import React, { Fragment, useEffect, useState } from "react";
import UserPanel from "./user-panel";
import { Link, useLocation } from "react-router-dom";
import { MENUITEMS } from "../../../constants/menu";
import { useSelector } from "react-redux";
import Logo from '../../../assets/images/dashboard/stats.png';

const Sidebar = () => {
    const [mainmenu, setMainMenu] = useState(MENUITEMS);
    const location = useLocation();
    const user = JSON.parse(localStorage.getItem("user"));
    const { profilelist } = useSelector(state => state.settings);

    useEffect(() => {
        const currentUrl = location.pathname;
        const updatedMenu = mainmenu.map((item) => {
            if (item.children) {
                item.children.map((subItem) => {
                    subItem.active = false;
                    if (subItem.children) {
                        subItem.children.map((subSubItem) => {
                            subSubItem.active = false;
                            if (subSubItem.path === currentUrl) {
                                subSubItem.active = true;
                                subItem.active = true;
                                item.active = true;
                            }
                            return subSubItem;
                        });
                    }
                    if (subItem.path === currentUrl) {
                        subItem.active = true;
                        item.active = true;
                    }
                    return subItem;
                });
            }
            if (item.path === currentUrl) {
                item.active = true;
            }
            return item;
        });
        setMainMenu(updatedMenu);
    }, [location.pathname]);

    const toggleNavActive = (item) => {
        const updatedMenu = mainmenu.map(menuItem => {
            if (menuItem === item) {
                menuItem.active = !menuItem.active;
            } else if (menuItem.children) {
                menuItem.children = menuItem.children.map(subItem => {
                    if (subItem === item) {
                        subItem.active = !subItem.active;
                    } else {
                        subItem.active = false;
                        if (subItem.children) {
                            subItem.children = subItem.children.map(childItem => {
                                childItem.active = false;
                                return childItem;
                            });
                        }
                    }
                    return subItem;
                });
            } else {
                menuItem.active = false;
            }
            return menuItem;
        });
        setMainMenu(updatedMenu);
    };

    const mainMenu = mainmenu.map((menuItem, i) => (
        <li className={`${menuItem.active ? "active" : ""}`} key={i}>
            {menuItem.sidebartitle ? (
                <div className="sidebar-title">{menuItem.sidebartitle}</div>
            ) : null}
            {menuItem.type === "sub" ? (
                <a
                    className="sidebar-header "
                    href="#javaScript"
                    onClick={(event) => { event.preventDefault(); toggleNavActive(menuItem); }}
                >
                    <menuItem.icon />
                    <span>{menuItem.title}</span>
                    <i className="fa fa-angle-right pull-right"></i>
                </a>
            ) : null}
            {menuItem.type === "link" ? (
                <Link
                    to={`${process.env.PUBLIC_URL}${menuItem.path}`}
                    className={`sidebar-header ${menuItem.active ? "active" : ""}`}
                    onClick={() => toggleNavActive(menuItem)}
                >
                    <menuItem.icon />
                    <span>{menuItem.title}</span>
                    {menuItem.children ? (
                        <i className="fa fa-angle-right pull-right"></i>
                    ) : null}
                </Link>
            ) : null}
            {menuItem.children ? (
                <ul
                    className={`sidebar-submenu ${menuItem.active ? "menu-open" : ""}`}
                    style={menuItem.active ? { opacity: 1, transition: "opacity 500ms ease-in" } : {}}
                >
                    {menuItem.children.map((childrenItem, index) => (
                        <li
                            key={index}
                            className={childrenItem.active ? "active" : ""}
                        >
                            {childrenItem.type === "sub" ? (
                                <a href="#javaScript" onClick={(event) => { event.preventDefault(); toggleNavActive(childrenItem); }}>
                                    <i className="fa fa-circle"></i>
                                    {childrenItem.title}{" "}
                                    <i className="fa fa-angle-right pull-right"></i>
                                </a>
                            ) : null}
                            {childrenItem.type === "link" ? (
                                <Link
                                    to={`${process.env.PUBLIC_URL}${childrenItem.path}`}
                                    className={childrenItem.active ? "active" : ""}
                                    onClick={() => toggleNavActive(childrenItem)}
                                >
                                    <i className="fa fa-circle"></i>
                                    {childrenItem.title}{" "}
                                </Link>
                            ) : null}
                            {childrenItem.children ? (
                                <ul
                                    className={`sidebar-submenu ${childrenItem.active ? "menu-open" : ""}`}
                                >
                                    {childrenItem.children.map((childrenSubItem, key) => (
                                        <li
                                            className={childrenSubItem.active ? "active" : ""}
                                            key={key}
                                        >
                                            {childrenSubItem.type === "link" ? (
                                                <Link
                                                    to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                                                    className={childrenSubItem.active ? "active" : ""}
                                                    onClick={() => toggleNavActive(childrenSubItem)}
                                                >
                                                    <i className="fa fa-circle"></i>
                                                    {childrenSubItem.title}
                                                </Link>
                                            ) : null}
                                        </li>
                                    ))}
                                </ul>
                            ) : null}
                        </li>
                    ))}
                </ul>
            ) : null}
        </li>
    ));

    return (
        <Fragment>
            <div className="page-sidebar">
                <div className="main-header-left d-none d-lg-block">
                    <div className="logo-wrapper" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Link to={`${process.env.PUBLIC_URL}/orders`} style={{ display: 'flex', alignItems: 'center', backgroundColor: 'red' }}>
                            <img className="blur-up lazyloaded" src={(profilelist?.image && profilelist?.logo) ? `${profilelist?.image}${profilelist?.logo}` : Logo} alt="" style={{ height: 50, alignSelf: 'center' }} />
                        </Link>
                    </div>
                </div>
                <div className="sidebar custom-scrollbar">
                    <UserPanel user={user} />
                    <ul className="sidebar-menu">{mainMenu}</ul>
                </div>
            </div>
        </Fragment>
    );
};

export default Sidebar;
