import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import AddUnit from "./AddUnit";
import { useDispatch, useSelector } from "react-redux";
import { deluniltList, getuniltList } from "../../../redux/actions/productActions";
import DataTables from "../../common/DataTables";
import {  toast } from 'react-toastify';
import { RESET_ERROR } from "../../../redux/constants/userConstants";
import DataTable from "react-data-table-component";
import SearchHeader from "../../common/header_components/searchHeader";
const Unit = () => {


    const dispatch = useDispatch()

    const { unitList, unitDeleteSuccess, newUnit, updatedunit,error } = useSelector(state => state.product)
    const { loading } = useSelector(state => state.auth)
    const [datas, setDatas] = useState([])
    const [activeData, setActiveData] = useState(null)
    const [value, setValue] = useState('')
    const [open, setOpen] = useState(false);
    const [filterText, setFilterText] = useState('');



    useEffect(() => {
        dispatch(getuniltList())
    }, [])

    useEffect(() => {
      if(unitList){
        setDatas(unitList)
      }
    }, [unitList])
    

    useEffect(() => {
        if (newUnit) {
            toast.success("New unit created successfully");
            dispatch({
                type: RESET_ERROR
            })
            onCloseModal()
        }  
    }, [newUnit])

    useEffect(() => {
        if (unitDeleteSuccess) {
            toast.success("Unit Deleted successfully");
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [unitDeleteSuccess])

    useEffect(()=>{
        if(error){
            toast.warning(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })

        }
    },[error])


    useEffect(() => {
        if (updatedunit) {
            toast.success(" unit Edited successfully");
            dispatch({
                type: RESET_ERROR
            })
            onCloseModal()
        }
    }, [updatedunit])
   

 
  

    const onOpenModal = () => {
        setActiveData(null)
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };


 


    // useEffect(() => {
    //     if (unitList) {
    //         setDatas([...unitList])
    //     }
    // }, [unitList])
    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const handleDelete = (id) => {
        if (window.confirm("Are you sure you wish to delete this item?")) {
            dispatch(deluniltList(id))
        }
    }


    const handleEdit = (data) => {
        setActiveData(data)
        setOpen(true)
    }

 


    var columns = [
        {
            name: <b>{Capitalize("S.No")}</b>,
            header: <b>{Capitalize("S.No")}</b>,
            cell: (row, index) => index + 1
        },
        {
            name: <b>{Capitalize("Unit Name")}</b>,
            header: <b>{Capitalize("Unit Name")}</b>,
            selector: row => row?.name,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("status")}</b>,
            header: <b>{Capitalize("status")}</b>,
            selector: row => row?.status,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>Actions</b>,
            id: "delete",
            accessor: (str) => "delete",
            cell: (row, index) => (

                <>
                    <span onClick={() => handleDelete(row._id)}>

                        <i
                            className="fa fa-trash"
                            style={{
                                width: 35,
                                fontSize: 20,
                                padding: 11,
                                color: "#e4566e",
                                cursor: "pointer"
                            }}
                        ></i>
                    </span>
                    <span onClick={() => handleEdit(row)}>
                        <i
                            className="fa fa-pencil"
                            style={{
                                width: 35,
                                fontSize: 20,
                                padding: 11,
                                color: "rgb(40, 167, 69)",
                                cursor: "pointer"
                            }}
                        ></i>
                    </span>
                </>
            )
        }
    ];

    useEffect(() => {
        //if(filterText){
            let datas = unitList?.filter(un => un?.name?.toLowerCase().includes(filterText?.toLowerCase()))
            setDatas(datas)
        //}
    }, [filterText])

    const subHeaderComponentMemo = React.useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setFilterText('');
			}
		};

		return (
            <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
                <SearchHeader onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
            </div>
		);
	}, [filterText]);

    





    return (
        <Fragment>
            <Breadcrumb title="Unit" parent="Products" />
            {/* <!-- Container-fluid starts--> */}
            <Container fluid="true">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <h5>unit</h5>
                            </CardHeader>
                            <CardBody>
                                <div className="btn-popup pull-right">
                                    <Button
                                        type="button"
                                        color="primary"
                                        onClick={onOpenModal}
                                        data-toggle="modal"
                                        data-original-title="test"
                                        data-target="#exampleModal"
                                    >
                                        Add Unit
                                    </Button>

                                </div>
                                <div className="clearfix"></div>
                                <div id="basicScenario" className="product-physical">
                                    {unitList && <DataTable
                                        pagination
                                        columns={columns}
                                        data={datas}
                                        fixedHeader  
                                        subHeader
                                        subHeaderComponent={subHeaderComponentMemo}    
                                    />}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <AddUnit openModal={open} onCloseModal={() =>{ 
                 setActiveData(null)
                 setOpen(false)
                }} data={activeData} />
            {/* <!-- Container-fluid Ends--> */}
        </Fragment>
    )
}

export default Unit