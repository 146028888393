import React from "react";

const unitdata = [
	{
		
		Unit_name: "Logo Designs",
		
		status: <i className="fa fa-circle font-success f-12" />,
	
	},
	{
		
		Unit_name: "Php",
		
		status: <i className="fa fa-circle font-success f-12"></i>,
		
	},
	{
	
		Unit_name: "HTML",
		
		status: <i className="fa fa-circle font-success f-12"></i>,
		
	},
	{

		Unit_name: "CSS",
		
		status: <i className="fa fa-circle font-success f-12"></i>,
		
	},
	{
		
		Unit_name: "Web element",
		
		status: <i className="fa fa-circle font-danger f-12"></i>,
	
	},
	{
		
		Unit_name: "Wordpress",
		
		status: <i className="fa fa-circle font-danger f-12"></i>,
	
	},
	{
	
		Unit_name: "3D Design",
		
		status: <i className="fa fa-circle font-success f-12"></i>,
	
	},
];

export default unitdata;
