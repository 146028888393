import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSingleOrderList } from '../../redux/actions/OrderActions';
import Table from 'react-bootstrap/Table';
// singleorderlist
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import DataTables from "../common/DataTables";
import moment from "moment/moment";
import Reactotron from "reactotron-react-js"
function OrderCustomerDetails() {
    let { id } = useParams();


    const { singleorderlist } = useSelector(state => state.orders);

    const [item, setItem] = useState([]);




    const dispatch = useDispatch();

    const [detaillist, setdetaillist] = useState([])

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };


    const rowTotal = (row) => {

        if (row?.total % 1 !== 0) {
            return row?.total
        } else {
            return row?.total?.toFixed(2)
        }

    }

    var columns = [
        {
            name: <b>{Capitalize("S.No")}</b>,
            header: <b>{Capitalize("S.No")}</b>,
            cell: (row, index) => index + 1
        },
        {
            name: <b>{Capitalize("Image")}</b>,
            header: <b>{Capitalize("Image")}</b>,
            cell: (row, index) => (
                <>
                    <span>
                        <img style={{ width: 40, height: 40 }} src={row?.image}></img>
                    </span>
                </>

            )
        },
        {
            name: <b>{Capitalize("Name")}</b>,
            header: <b>{Capitalize("Name")}</b>,
            selector: row => `${row?.name}(${row?.variant?.name})`,
            style: {
                textAlign: "center",
            },

        },
        {
            name: <b>{Capitalize("Category")}</b>,
            header: <b>{Capitalize("Category")}</b>,
            selector: row => row?.category?.name,
            style: {
                textAlign: "center",
            },

        },
        {
            name: <b>{Capitalize("Unit")}</b>,
            header: <b>{Capitalize("Unit")}</b>,
            selector: row => row?.unit?.name + ` ( ${row?.variant?.name} )`,
            style: {
                textAlign: "center",
            },

        },
        // {
        //     name: <b>{Capitalize("Sub Category")}</b>,
        //     header: <b>{Capitalize("Sub Category")}</b>,
        //     selector: row => row?.subCategory
        //         ?.name,
        //     style: {
        //         textAlign: "center",
        //     },

        // },
        {
            name: <b>{Capitalize("qty")}</b>,
            header: <b>{Capitalize("qty")}</b>,
            selector: row => row?.qty,
            style: {
                textAlign: "center",
            },
        },
        // {
        //     name: <b>{Capitalize("price")}</b>,
        //     header: <b>{Capitalize("price")}</b>,
        //     selector: row => row?.price,
        //     style: {
        //         textAlign: "center",
        //     },
        // },
        // {
        //     name: <b>{Capitalize("costPrice")}</b>,
        //     header: <b>{Capitalize("costPrice")}</b>,
        //     selector: row => row?.costPrice,
        //     style: {
        //         textAlign: "center",
        //     },
        // },
        // {
        //     name: <b>{Capitalize("razorPayId")}</b>,
        //     header: <b>{Capitalize("razorPayId")}</b>,
        //     selector: row => row?.razorPayId,
        //     style: {
        //         textAlign: "center",
        //     },
        // },
        {
            name: <b>{Capitalize("Price")}</b>,
            header: <b>{Capitalize("Price")}</b>,
            selector: row => row?.qty * parseFloat(row?.price).toFixed(2),
            style: {
                textAlign: "center",
            },
        },
        // {
        //     name: <b>{Capitalize("orderStatus")}</b>,
        //     header: <b>{Capitalize("orderStatus")}</b>,
        //     selector: row => row?.orderStatus,
        //     style: {
        //         textAlign: "center",
        //     },
        // },



    ];

    // useEffect(() => {
    // if(singleorderlist){
    //     setdetaillist[singleorderlist.itemDetails]
    // }
    // }, [singleorderlist])


    useEffect(() => {
        if (id) {
            dispatch(getSingleOrderList(id))
        }
    }, [id])
    // console.log({ singleorderlist })

    if (!singleorderlist) {
        return <>loading...</>
    }

    Reactotron.log(singleorderlist,"singleorderlist")
    return (
        <Fragment>
            <Breadcrumb title="Order Details" parent="Order List" />
            <Container fluid={true}>

                <Col xl="12">


                    <Card>
                        <Row xl={"2"}>
                            <Col xl={"6"}>
                                <div>
                                    <CardHeader>
                                        <h5>Billing Address</h5>
                                    </CardHeader>
                                    <CardBody>

                                        <div className="table-responsive profile-table">

                                            <div>
                                                <div className="mb-1">
                                                    <span style={{ fontWeight: 500 }}>Customer Name: </span>
                                                    <span>{singleorderlist?.customer?.name}</span>
                                                </div>
                                                <div className="mb-1">
                                                    <span style={{ fontWeight: 500 }}>Address: </span>
                                                    <span>{singleorderlist?.billingAddress?.area?.user_address}, {singleorderlist?.billingAddress?.area?.address}</span>
                                                     {/* {singleorderlist?.shippingAddress?.pincode ? (<span>, {singleorderlist?.shippingAddress?.pincode}</span>) : null} */}
                                                </div>
                                                <div className="mb-1">
                                                    <span style={{ fontWeight: 500 }}>Email: </span>
                                                    <span>{singleorderlist?.customer?.email}</span>
                                                </div>
                                                <div className="mb-1">
                                                    <span style={{ fontWeight: 500 }}>Mobile: </span>
                                                    <span>{singleorderlist?.billingAddress?.mobile}</span>
                                                </div>
                                                {singleorderlist?.billingAddress?.area?.location ? (<div className="mb-1">
                                                    <span style={{ fontWeight: 500 }}>Landmark: </span>
                                                    <span>{singleorderlist?.billingAddress?.area?.location}</span>
                                                </div>) : null}
                                                {singleorderlist?.billingAddress?.comments ? (<div className="mb-1">
                                                    <span style={{ fontWeight: 500 }}>Comments: </span>
                                                    <span>{singleorderlist?.billingAddress?.comments}</span>
                                                </div>) : null}
                                            </div>
                                        </div>

                                    </CardBody>
                                </div>
                                <div>
                                    <CardHeader>
                                        <h5>Shipping Address</h5>
                                    </CardHeader>
                                    <CardBody>

                                        <div className="table-responsive profile-table">
                                            <div>
                                                <div className="mb-1">
                                                    <span style={{ fontWeight: 500 }}>Customer Name: </span>
                                                    <span>{singleorderlist?.customer?.name}</span>
                                                </div>
                                                <div className="mb-1">
                                                    <span style={{ fontWeight: 500 }}>Address: </span>
                                                    <span>{singleorderlist?.shippingAddress?.area?.user_address}, {singleorderlist?.shippingAddress?.area?.address}</span>
                                                   {/* {singleorderlist?.shippingAddress?.pincode ? (<span>, {singleorderlist?.shippingAddress?.pincode}</span>) : null} */}
                                                </div>
                                                <div className="mb-1">
                                                    <span style={{ fontWeight: 500 }}>Email: </span>
                                                    <span>{singleorderlist?.customer?.email}</span>
                                                </div>
                                                <div className="mb-1">
                                                    <span style={{ fontWeight: 500 }}>Mobile: </span>
                                                    <span>{singleorderlist?.shippingAddress?.mobile}</span>
                                                </div>
                                                {singleorderlist?.shippingAddress?.area?.location ? (<div className="mb-1">
                                                    <span style={{ fontWeight: 500 }}>Landmark: </span>
                                                    <span>{singleorderlist?.shippingAddress?.area?.location}</span>
                                                </div>) : null}
                                                {singleorderlist?.shippingAddress?.comments ? (<div className="mb-1">
                                                    <span style={{ fontWeight: 500 }}>Comments: </span>
                                                    <span>{singleorderlist?.shippingAddress?.comments}</span>
                                                </div>) : null}
                                            </div>
                                        </div>

                                    </CardBody>
                                </div>
                            </Col>
                            <div>
                                <CardHeader>
                                    <h5>Order Information</h5>
                                </CardHeader>
                                <CardBody>

                                    <div className="table-responsive profile-table">
                                        <Table className="table-responsive">
                                            <tbody>
                                                <tr>
                                                    <td>Order Date</td>
                                                    <td>{moment(singleorderlist?.orderDate, "YYYY-MM-DD").format("DD-MM-YYYY")}</td>
                                                </tr>
                                                <tr>
                                                    <td>Order Status:</td>
                                                    <td>{singleorderlist?.orderStatus}</td>
                                                </tr>
                                                <tr>
                                                    <td>Payment Type</td>
                                                    <td>{singleorderlist?.paymentType?.toUpperCase()}</td>
                                                </tr>
                                                <tr>
                                                    <td>Payment Status</td>
                                                    <td>{singleorderlist?.paymentStatus[0]?.toUpperCase() + singleorderlist?.paymentStatus?.slice(1)}</td>
                                                </tr>
                                                {singleorderlist?.paymentType?.toLowerCase() === "online" && <tr>
                                                    <td>Transaction Id:</td>
                                                    <td>{singleorderlist?.razorPayId}</td>
                                                </tr>}
                                                <tr>
                                                    <td>Sub Total:</td>
                                                    <td>₹ {singleorderlist?.subTotal}</td>
                                                </tr>
                                                <tr>
                                                    <td>Discount:</td>
                                                    <td>₹ {singleorderlist?.discount}</td>
                                                </tr>
                                                <tr>
                                                    <td>Tax:</td>
                                                    <td>₹ {singleorderlist?.tax && singleorderlist?.tax?.toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total:</td>
                                                    <td>₹ {singleorderlist?.total && singleorderlist?.total?.toFixed(2)}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>

                                </CardBody>
                            </div>

                        </Row>

                    </Card>
                </Col>
                <Row />


                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <h5>Item Details</h5>
                            </CardHeader>
                            <CardBody>

                                <div className="clearfix"></div>
                                <div id="basicScenario" className="product-physical">
                                    <Fragment>
                                        <DataTables
                                            columns={columns}
                                            row={singleorderlist?.itemDetails
                                                ? singleorderlist?.itemDetails : []}
                                        />

                                    </Fragment>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>


            </Container>

        </Fragment>
    )
}

export default OrderCustomerDetails