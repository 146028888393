import React, { useCallback, useEffect, useState } from "react";
import {
  Modal,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Label,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Select from "react-select";
import { Controller } from "react-hook-form";

import { object, string, mixed } from "yup";
import customAxios from "../../../customAxios";

const TopicModal = ({
  openModal,
  onCloseModal,
  setTopicData,
  editItem,
  topicData,
}) => {
  const [topics, setTopics] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [blogData, setBlogData] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(null);

  useEffect(() => {
    if (editItem) {
      setSelectedTopic(editItem?.topic);
      setValue("topic", editItem?.topic);
      setBlogData(editItem?.blog);
      setValue("blog", editItem?.blog);
    }

    getTopics();

  }, [topicData, editItem]);

  useEffect(() => {
    const getBlogs = async () => {
      try {
        const blogs = await customAxios.get(
          "admin/topics/blogs/" + selectedTopic?.value
        );

        setBlogs(
          blogs?.data?.data?.map(({ title, _id }) => ({
            label: title,
            value: _id,
          }))
        );

        if (!editItem) {
          setBlogData([]);
        }
      } catch (error) {
      } finally {
        // setLoading(false)
      }
    };

    if (selectedTopic) {
      getBlogs();
    }
  }, [selectedTopic, editItem]);

  const getTopics = useCallback(async () => {
    try {
      const topics = await customAxios.get("admin/topic-list");

      let topicId = topicData?.map((item) => item?.topic?.value);
      const filtered = topics?.data?.data?.filter(
        (item) => !topicId?.includes(item?._id)
      );
      setTopics(
        filtered?.map((item) => ({ label: item?.name, value: item?._id }))
      );
    } catch (error) {
    } finally {
      // setLoading(false)
    }
  }, [topicData])
  

  const schema = object()
    .shape({
      topic: yup
        .object()
        .required("Topic is required")
        .typeError("Topic is required"),
      blog: yup
        .array()
        .required("Blog is required")
        .typeError("Blog is required"),
    })
    .required();

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onChangeBlog = useCallback(
    (blogs) => {
      setBlogData(blogs);
    },
    [selectedTopic]
  );

  const onsubmit = useCallback(
    (data) => {

      if(blogData?.length === 0) {
        setValue('blog', null)

        return setError('blog', { type: 'custom', message: 'Blog is required'});
      }


      setTopicData((topicData) => {
        const findData = topicData?.find(
          ({ topic }) => selectedTopic?.value === topic?.value
        );

        if (findData) {
          const filter = topicData?.filter(
            ({ topic }) => selectedTopic?.value !== topic?.value
          );

          return [...filter, { topic: selectedTopic, blog: blogData }];
        }

        return [...topicData, { topic: selectedTopic, blog: blogData }];
      });

      closeModal();
    },
    [blogData, selectedTopic]
  );

  const closeModal = () => {
    onCloseModal();
    setBlogData(null);
    setSelectedTopic(null);
    setValue("topic", null);
    setValue("blog", null);
    setError("topic", { type: "custom", message: null });
    setError("blog", { type: "custom", message: null });
  };

  return (
    <Modal isOpen={openModal} toggle={closeModal}>
      <ModalHeader toggle={closeModal}>
        <h5 className="modal-title f-w-600" id="exampleModalLabel2">
          Add Topic
        </h5>
      </ModalHeader>
      <ModalBody>
        <Form>
          <Label htmlFor="recipient-name" className="col-form-label">
            {"Topic"}
          </Label>

          <Controller
            name={"topic"}
            control={control}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <>
                <Select
                  styles={{
                    control: (css) => ({
                      ...css,
                      width: "100%",
                    }),
                  }}
                  value={selectedTopic}
                  // onBlur={onBlur}
                  // aria-invalid={error ? "true" : "false"}
                  // maxLength={max}
                  isDisabled={editItem}
                  // isMulti={isMultiple}
                  options={topics}
                  onChange={(data) => {
                    setSelectedTopic(data);
                    onChange(data);
                  }}
                />
                {error && (
                  <p
                    role="alert"
                    style={{
                      color: "red",
                      display: "flex",
                      flexDirection: "start",
                      fontSize: "12px",
                    }}
                  >
                    {error?.message}
                  </p>
                )}
              </>
            )}
          />

          <Label htmlFor="recipient-name" className="col-form-label">
            {"Blogs"}
          </Label>

          <Controller
            name={"blog"}
            control={control}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <>
                <Select
                  styles={{
                    control: (css) => ({
                      ...css,
                      width: "100%",
                    }),
                  }}
                  value={blogData}
                  // onBlur={closeModal}
                  isMulti
                  options={blogs}
                  onChange={(data) => {
                    onChangeBlog(data);
                    onChange(data);
                  }}
                />

                {error && (
                  <p
                    role="alert"
                    style={{
                      color: "red",
                      display: "flex",
                      flexDirection: "start",
                      fontSize: "12px",
                    }}
                  >
                    {error?.message}
                  </p>
                )}
              </>
            )}
          />
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="primary" onClick={handleSubmit(onsubmit)}>
          Save
        </Button>
        <Button type="button" color="danger" onClick={closeModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TopicModal;
