import React, { useState, useEffect } from 'react'
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CustomTextArea from '../common/CustomTextArea';
import ImageInput from '../common/ImageInput';
import CustomInput from '../common/CustomInput';
import ImagesInput from '../common/ImagesInput';
import axios from 'axios';
import customAxios from '../../customAxios';
import { toast } from 'react-toastify';
import SelectInput from '../common/SelectInput';
import Reactotron from "reactotron-react-js"
import DraftEditor from '../Editor/DraftEditor';
import SunEditorComponent from '../SunEditor/Editor';
import SEditor from '../SEditor/SunEditor';




const AddBlog = ({ openModal, onCloseModal, id }) => {

    const [loading, setLoading] = useState(false)
    const [topics, setTopics] = useState([]);
    const [blogDetails, setBlogDetails] = useState({})
    const [description, setDescription] = useState("");
    const [topicId, setTopicId] = useState('');
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const schema = yup.object({
        title: yup.string().max(40).required("Title field is required"),
        description: yup.string().required("Description field is required"),
        image: yup.mixed().required("Image field is required"),
        topic: yup.array().required("Blog topics is required").typeError("Please select topic")
    }).required();

    const { register, handleSubmit, setFieldValue, control, formState: { errors }, reset, setValue, setError, watch, getValues } = useForm({
        resolver: yupResolver(schema)
    });


    const getBlogTopics = async () => {
        if(!id){
            setLoader(true);
            setDescription(null);

            setTimeout(() => {
                setLoader(false)
            }, 500);
            
        }
        try {
            setLoading(true)
            let topics = await customAxios.get(`admin/topics`);

            const items = topics?.data?.data?.map(({ name, _id }) => ({
                label: name,
                value: _id
            }))

            setTopics(items)

        } catch (error) {
            toast.error(error)
        }
        finally {
            setLoading(false)
        }
    }


    useEffect(() => {
        if (id) {
            getBlogDetails(id)
        }

    }, [id])

    useEffect(() => {
        setBlogDetails(null)
        setValue("description", null)
        setDescription(null)
        getBlogTopics();

        
    }, [])


    const getBlogDetails = async (id) => {
        setLoader(true)
        setDescription('')

        try {
            setLoading(true)
            let blog = await customAxios.get(`admin/blog/${id}`);

            let datas = blog?.data?.data

            setBlogDetails(datas)

            let data = {
                title: datas?.title,
                description: datas?.description,
                image: datas?.image,
                videourl: datas?.videourl,
                topic: datas?.topics,
                detail_description: datas?.detail_description
            }

            setDescription(datas?.detail_description)

            // console.log(topics);


            reset(data)
            
        } catch (error) {
            toast.error(error)
        }
        finally {
            setLoader(false)
        }


    }

    const onSubmit = async (data) => {

        Reactotron.log(data, "pay")


        let url = 'admin/create-blog';
        if (id) {
            url = `admin/edit-blog`;
            data['id'] = id;
        }

        setLoading(true)


        try {
            let datas = await customAxios.post(url, {
                ...data,
                topic: data?.topic?.map(({ value }) => value)
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })


            toast.success(datas.data.message)
            Reactotron.log(datas, "datass")
            // console.log(data, 'ksdkdk');
            reset({
                title: "",
                description: "",
                image: null,
                videourl: "",
                topic: ""
            })
        } catch (error) {
            toast.error(error?.message)
        }
        finally {
            setLoading(false)
            onCloseModal()
        }






    };

    // console.log(getValues());

    const clearclose = () => {
        reset({
            title: "",
            description: "",
            image: null,
            videourl: "",
            topic: ""
        })
        onCloseModal()
    }


    if(loader){
        return(
            <div>Loading...</div>
        )
    }



    return (

        <Modal isOpen={openModal} toggle={clearclose}>
            <ModalHeader toggle={clearclose}>
                <h5
                    className="modal-title f-w-600"
                    id="exampleModalLabel2"
                >
                    {id ? "Edit Blog" : "Add Blog"}
                </h5>
            </ModalHeader>
            <ModalBody>
                <Form>
                    <CustomInput
                        fieldLabel='Title :'
                        control={control}
                        error={errors.title}
                        fieldName='title'
                    />
                    <CustomTextArea
                        fieldLabel='Description :'
                        control={control}
                        error={errors.description}
                        fieldName='description'
                        type="textarea"
                        defaultValue={id ? blogDetails?.description : null}
                    />
                    {/* <DraftEditor /> */}
                    {/* <SunEditorComponent
                        value={watch('description')}
                        onChange={(content) => setValue('description', content)}
                        error={errors.description}
                    /> */}

                    <SEditor
                        value={id ? description : null}
                        onChange={(content) => {
                            setValue('detail_description', content)
                            setDescription(content)
                        }}
                        error={errors.description}
                        //disabled={true}
                        
                    />



                    {id ? (<img
                        src={blogDetails?.image}
                        style={{ width: "300px", height: "auto", objectFit: "cover" }}
                    />) : null}

                    <ImagesInput
                        fieldLabel='Image ( Recommended Size 400 x 250 ) :'
                        control={control}
                        error={errors.image}
                        fieldName='image'
                        type='file'
                        onChangeValue={(file) => {
                            setValue("image", file)
                            setError('image', { type: 'custom', message: null });

                        }}

                    />
                    <CustomInput
                        fieldLabel='Video URL:'
                        control={control}
                        error={errors.videourl}
                        fieldName='videourl'
                    />

                    <SelectInput
                        fieldLabel='Blog Topics'
                        control={control}
                        error={errors.topic}
                        fieldName='topic'
                        options={topics}
                        isMultiple
                    // onChangeValue={onChangeValue}
                    />
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    color="primary"
                    onClick={handleSubmit(onSubmit)}
                    disabled={loading}
                >
                    {loading ? "Loading..." : "Save"}
                </Button>
            </ModalFooter>
        </Modal>

    )
}

export default AddBlog
