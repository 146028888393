import React, { useState, useEffect } from 'react'
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import CustomInput from '../../common/CustomInput';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import unitdata from '../unit/unitdata';
import SelectInput from '../../common/SelectInput';
import { useDispatch, useSelector } from "react-redux";
import { GetCategory, getuniltList } from '../../../redux/actions/productActions';
import moment from 'moment';

const AddUnitmodal = ({ openModal, onCloseModal, func, unitlistmap }) => {

    const dispatch = useDispatch();
    const { unitList, unitDeleteSuccess, newUnit, updatedunit, getcatogrylist, } = useSelector(state => state.product)
    const [unit, setUnit] = useState('');
    const [start, setstart] = useState('');
    const [end, setend] = useState('');
    const [offer, setOffer] = useState(false);


    const changeUnit = (event) => {
        setUnit(event.target.value);
    };
    const schema = yup.object({
        name: yup.string().required("Name field is required"),
        Selling_Price: yup.string().test(
            'Is positive?',
            ' The number must be greater than 0!',
            (value) => value > 0
        ).required(),
        Regular_Price: yup.string().test(
            'Is positive?',
            ' The number must be greater than 0!',
            (value) => value > 0
        ).required(),
        unit_id: yup.object().required("Please select unit").typeError("Please select unit"),
        // Offer_Price:yup.string().test(
        //     'Is positive?', 
        //     ' The number must be greater than 0!', 
        //     (value) => value > 0
        //   )

    }).required();

    const { register, handleSubmit, setFieldValue, control, formState: { errors }, reset, setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            unit_id: '',
            name: '',
            Selling_Price: '',
            Regular_Price: '',
            Offer_Price: '',
            startDate: '',
            EndDate: ''
        }
    });


    const offerselect = (e, checked) => {

        if (checked) {
            setValue("startDate", new Date())
            setValue("EndDate", new Date())
            setOffer(true)
            
        } else {
            setValue("Offer_Price", "")
            setOffer(false)
        }
    }


    const transferValue = (event) => {
        // event.preventDefault()


        const val = {
            uid: moment().unix(),
            id: event.unit_id,
            name: event.name,
            description: '',
            selling: event.Selling_Price,
            regular: event.Regular_Price,
            offer:event.Offer_Price,
            fromDate: event.startDate,
            toDate: event.EndDate
        };
        //console.log({val})
        func(val);
        reset()
        setOffer(false)
        onCloseModal();

    };

    // useEffect(() => {
    //     dispatch(getuniltList())
    //     dispatch(GetCategory())
    // }, [])



    const handlechangestartdate = (date) => {

        setstart(date)
    }
    const handlechangeEnddate = (date) => {
        setend(date)
    }
    const getcategoryid = (value) => {
    }

    const cleardata = () => {
        reset()
        onCloseModal()
    }

    return (
        <Modal isOpen={openModal} toggle={cleardata}>
            <ModalHeader toggle={cleardata}>
                <h5
                    className="modal-title f-w-600"
                    id="exampleModalLabel2"
                >
                    Add Product Variant
                </h5>
            </ModalHeader>
            <ModalBody>
                <Form>
                    <SelectInput 
                        fieldLabel='Unit'
                        control={control}
                        error={errors.unit_id}
                        fieldName='unit_id'
                        options={unitlistmap}
                    />
                    <div>
                        <CustomInput
                            fieldLabel={'Variant Value:'}
                            control={control}
                            error={errors.name}
                            fieldName={'name'}
                        />
                    </div>
                    {/* <div>

                        <CustomInput
                            fieldLabel='Variant Description'
                            control={control}
                            error={errors.Variant_Description}
                            fieldName={'Variant_Description'}
                        />
                    </div> */}
                    <div>

                        <CustomInput
                            fieldLabel='Selling Price'
                            control={control}
                            error={errors.Selling_Price}
                            fieldName={'Selling_Price'}
                            type="number"
                        />
                    </div>
                    <div>

                        <CustomInput
                            fieldLabel='Regular Price'
                            control={control}
                            error={errors.Regular_Price}
                            fieldName={'Regular_Price'}
                            type="number"
                        />
                    </div>

                    <div style={{ display: "flex", alignItems: "center", gap: "10px", }}>
                        <input type="checkbox" onChange={(e) => offerselect(e.target.value, e.target.checked)} style={{ cursor: "pointer" }}></input>
                        <label>Add Offer Price</label>

                    </div>
                    {offer ? <div>

                        <CustomInput
                            fieldLabel='Offer Price'
                            control={control}
                            error={errors.Offer_Price}
                            fieldName={'Offer_Price'}
                            type="number"
                        />
                        <div style={{ display: "flex", justifyContent: "space-between" }}>


                            <CustomInput
                                fieldLabel={'Start Date'}
                                control={control}
                                error={errors.startDate}
                                fieldName={'startDate'}
                                type={'date'}
                                minimum={moment().format("YYYY-MM-DD")}
                            />

                            <CustomInput
                                fieldLabel={'End Date'}
                                control={control}
                                error={errors.EndDate}
                                fieldName={'EndDate'}
                                type={'date'}
                            />
                        </div>

                    </div>

                        : ""}

                </Form>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    color="primary"
                    onClick={handleSubmit(transferValue)}
                >
                    Save
                </Button>
                <Button
                    type="button"
                    color="secondary"
                    onClick={onCloseModal}
                >
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default AddUnitmodal