
import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { DELFEATURED, GETFEATURED } from "../../../redux/actions/productActions";
import DataTables from "../../common/DataTables";
import { toast } from 'react-toastify';
import { RESET_ERROR } from "../../../redux/constants/userConstants";
import AddFeature from "./AddFeature";

const Featured = () => {
    const dispatch = useDispatch()
    const { featuredList, featuredcreated, featuredeleted, updatedfeature, error } = useSelector(state => state.product)
    const [open, setOpen] = useState(false);
    const [openedit, setOpenEdit] = useState(false);
    const [activeData, setActiveData] = useState(null)
    const [datas, setDatas] = useState(null)

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const handleDelete = (id) => {
        if (window.confirm("Are you sure you wish to delete this item?")) {
            dispatch(DELFEATURED(id))
        }

    }

    const handleEdit = (data) => {
        setActiveData(data)
        setOpen(true)

    }

    const onOpenModal = () => {

        setOpen(true);
    };

    const onCloseModal = () => {
        setActiveData(null)
        setOpen(false);

    };
    const onOpenEditModal = () => {
        setOpenEdit(true);
    };

    const onCloseEditModal = () => {
        setOpenEdit(false);
    };

    useEffect(() => {
        dispatch(GETFEATURED())
    }, [])

    useEffect(() => {
        if (featuredcreated) {
            toast.success(" Featured Created successfully");
            dispatch({
                type: RESET_ERROR
            })
        }
        onCloseModal()
    }, [featuredcreated])


    useEffect(() => {
        if (featuredeleted) {
            toast.success(" Featured Deleted successfully");
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [featuredeleted])
    useEffect(() => {
        if (updatedfeature) {
            toast.success(" Featured Edited successfully");
            dispatch({
                type: RESET_ERROR
            })
        }
        onCloseModal()
    }, [updatedfeature])

    useEffect(() => {
        if (error) {
            toast.error(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [error])



    var columns = [
        {
            name: <b>{Capitalize("S.No")}</b>,
            header: <b>{Capitalize("S.No")}</b>,
            cell: (row, index) => index + 1
        },

        {
            name: <b>{Capitalize("Featured Name")}</b>,
            header: <b>{Capitalize("Featured Name")}</b>,
            selector: row => row?.name,
            style: {
                textAlign: "center",
            },
        },

        {
            name: <b>Actions</b>,
            id: "delete",
            accessor: (str) => "delete",
            cell: (row, index) => (

                <>
                    <span onClick={() => handleDelete(row._id)}>

                        <i
                            className="fa fa-trash"
                            style={{
                                width: 35,
                                fontSize: 20,
                                padding: 11,
                                color: "#e4566e",
                                cursor: "pointer"
                            }}
                        ></i>
                    </span>
                    <span onClick={() => handleEdit(row)}>
                        <i
                            className="fa fa-pencil"
                            style={{
                                width: 35,
                                fontSize: 20,
                                padding: 11,
                                color: "rgb(40, 167, 69)",
                                cursor: "pointer"
                            }}
                        ></i>
                    </span>
                </>
            )
        }
    ];
    return (
        <Fragment>
            <Breadcrumb title="Featured" parent="Products" />
            {/* <!-- Container-fluid starts--> */}
            <Container fluid="true">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <h5>Featured</h5>
                            </CardHeader>
                            <CardBody>
                                <div className="btn-popup pull-right">
                                    <Button
                                        type="button"
                                        color="primary"
                                        onClick={() => (
                                            onOpenModal(),
                                            setActiveData(null)
                                        )}
                                        data-toggle="modal"
                                        data-original-title="test"
                                        data-target="#exampleModal"

                                    >
                                        Add Feature

                                    </Button>

                                </div>
                                <div className="clearfix"></div>
                                <div id="basicScenario" className="product-physical">
                                    {featuredList && <DataTables
                                        columns={columns}
                                        row={featuredList ? featuredList : []}
                                    />}

                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <AddFeature openModal={open} onCloseModal={() => {
                setActiveData(null)
                setOpen(false)
            }}
                data={activeData} setActiveData={setActiveData} />

            {/* <!-- Container-fluid Ends--> */}
        </Fragment>
    )
}

export default Featured