import React, { useEffect } from 'react'
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import CustomInput from '../.././common/CustomInput';

import { useDispatch, useSelector } from "react-redux";
import { CREATEPROFILE, EDITSETTING, GETPROFILE } from '../../../redux/actions/SettingActions';
import ImagesInput from '../../common/ImagesInput';
import { RESET_ERROR } from '../../../redux/constants/userConstants';
import { useState } from 'react';
import { object, string, mixed } from "yup"
const ProfileModal = ({ openModal, onCloseModal, datalist, error }) => {

    const { profilecreated, profilelist, profileupdated } = useSelector(state => state.settings)
   
    const dispatch = useDispatch()
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const schema = object().shape({
        storeemail: yup.string().email().required("email is required"),
        storename: yup
            .string()
            .max(40)
            .required(),
        address: yup
            .string()
            .max(250)
            .required(),
        mobile: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
        gst: yup.number("gst value is required").positive("number can't start with a minus")
            .required("gst is required"),
        delivery_charge: yup.string().matches(/[0-9]+/, 'not valid').required('Delivery charge required'),
        free_delivery: yup.string().matches(/[0-9]+/, 'not valid').required('Free Delivery charge required')

    }).required();



    const { register, handleSubmit, setFieldValue, control, formState: { errors }, reset, setValue } = useForm({

        resolver: yupResolver(schema),


    });

    const [itemSwitch, setItemSwitch] = useState(false)



    const onsubmit = (data) => {
        const formData = new FormData();
        formData.append('delivery_slot', itemSwitch === true ? 1 : 0);
        formData.append('id', datalist?._id);
        formData.append('address', data?.address);
        formData.append('storename', data?.storename);
        formData.append('storeemail', data?.storeemail);
        formData.append('mobile', data?.mobile);
        formData.append('delivery_charge', data?.delivery_charge);
        formData.append('freedeliveryvalue', data?.free_delivery);
        formData.append('gst', data?.gst);
        formData.append('gstin', data?.gstin);
        formData.append('hsn_code', data?.hsn_code);
        formData.append('whatsapp_link', data?.whatsapp_link);
        formData.append('booking_link', data?.booking_link);
        formData.append('facebook_link', data?.facebook_link);
        formData.append('instagram_link', data?.instagram_link);
        formData.append('linkedin_link', data?.linkedin_link);
        formData.append('primary_color', data?.primary_color);
        formData.append('secondary_color', data?.secondary_color);
        formData.append('tertiary_color', data?.tertiary_color);
        formData.append('header_color', data?.header_color ? data?.header_color : null);
        formData.append('footer_color', data?.footer_color ? data?.footer_color : null);
        formData.append('footer_text_color', data?.footer_text_color ? data?.footer_text_color : null);
        formData.append('body_color', data?.body_color ? data?.body_color : null);
        if (data?.image) {
            formData.append('logo', data?.image);
        }

        dispatch(EDITSETTING(formData))
    }

    useEffect(() => {
        if (datalist) {
            setValue("storename", datalist?.storename)
            setValue("storeemail", datalist?.storeemail)
            setValue("address", datalist?.address)
            setValue("mobile", datalist?.mobile)
            setValue("gst", datalist?.gst)
            setValue("gstin", datalist?.gstin)
            setValue("hsn_code", datalist?.hsn_code)
            setValue("whatsapp_link", datalist?.whatsapp_link)
            setValue("booking_link", datalist?.booking_link)
            setValue("facebook_link", datalist?.facebook_link)
            setValue("instagram_link", datalist?.instagram_link)
            setValue("linkedin_link", datalist?.linkedin_link)
            setValue("delivery_charge", datalist?.delivery_charge);
            setValue("primary_color",datalist?.primary_color)
            setValue("tertiary_color",datalist?.tertiary_color)
            setValue("secondary_color",datalist?.secondary_color)
            setValue("header_color",datalist?.header_color)
            setValue("footer_color",datalist?.footer_color)
            setValue("footer_text_color",datalist?.footer_text_color)
            setValue("body_color",datalist?.body_color)
            setValue('free_delivery', datalist?.freedeliveryvalue)
            setItemSwitch(datalist?.delivery_slot === 1 ? true : false)
        }

    }, [datalist])


    return (
        <Modal isOpen={openModal} toggle={onCloseModal}>
            <ModalHeader toggle={onCloseModal}>
                <h5
                    className="modal-title f-w-600"
                    id="exampleModalLabel2"
                >
                    Edit Profile
                </h5>
            </ModalHeader>
            <ModalBody>
                <Form>
                    <Row xl="2">
                        <CustomInput
                            fieldLabel='Store Name'
                            control={control}
                            error={errors.storename}
                            fieldName='storename'
                        />
                        <CustomInput
                            fieldLabel='Store Email'
                            control={control}
                            error={errors.storeemail}
                            fieldName='storeemail'
                        />
                    </Row>

                    <Row xl="2">
                        <CustomInput
                            fieldLabel='Address'
                            control={control}
                            error={errors.address}
                            fieldName='address'
                        />
                        <CustomInput
                            fieldLabel='Mobile'
                            control={control}
                            error={errors.mobile}
                            fieldName='mobile'
                            type={"number"}
                        />
                    </Row>

                    <Row xl="2">
                        <CustomInput
                            fieldLabel='Delivery Charge'
                            control={control}
                            error={errors.delivery_charge}
                            fieldName='delivery_charge'
                            type={"number"}
                        />

                        <CustomInput
                            fieldLabel='GST(%)'
                            control={control}
                            error={errors.gst}
                            fieldName='gst'
                            type={"number"}
                        />
                    </Row>

                    <CustomInput
                            fieldLabel='Free Delivery Charge'
                            control={control}
                            error={errors.free_delivery}
                            fieldName='free_delivery'
                            type={"number"}
                        />

                    <Row xl="2">
                        <CustomInput
                            fieldLabel='GSTIN'
                            control={control}
                            error={errors.gstin}
                            fieldName='gstin'
                        />
                        <CustomInput
                            fieldLabel='HSN Code'
                            control={control}
                            error={errors.hsn_code}
                            fieldName='hsn_code'
                        />
                    </Row>

                    <CustomInput
                        fieldLabel='Booking Link'
                        control={control}
                        error={errors.booking_link}
                        fieldName='booking_link'
                    />

                    <CustomInput
                        fieldLabel='Whatsapp Link'
                        control={control}
                        error={errors.whatsapp_link}
                        fieldName='whatsapp_link'
                    />

                    <CustomInput
                        fieldLabel='Facebook Link'
                        control={control}
                        error={errors.facebook_link}
                        fieldName='facebook_link'
                    />

                    <CustomInput
                        fieldLabel='Instagram Link'
                        control={control}
                        error={errors.instagram_link}
                        fieldName='instagram_link'
                    />

                    <CustomInput
                        fieldLabel='Linkedin Link'
                        control={control}
                        error={errors.linkedin_link}
                        fieldName='linkedin_link'
                    />

                    <Row xl="3">
                        <CustomInput
                            fieldLabel='Primary Color'
                            control={control}
                            error={errors.primary_color}
                            fieldName='primary_color'
                            type={"color"}
                        />
                        <CustomInput
                            fieldLabel='Secondary Color'
                            control={control}
                            error={errors.secondary_color}
                            fieldName='secondary_color'
                            type={"color"}
                        />
                           <CustomInput
                            fieldLabel='Tertiary Color'
                            control={control}
                            error={errors.tertiary_color}
                            fieldName='tertiary_color'
                            type={"color"}
                        />
                    </Row>
                    <Row xl="3">
                        <CustomInput
                            fieldLabel='Header Color'
                            control={control}
                            error={errors.header_color}
                            fieldName='header_color'
                            type={"color"}
                        />
                        <CustomInput
                            fieldLabel='Body Color'
                            control={control}
                            error={errors.body_color}
                            fieldName='body_color'
                            type={"color"}
                        />
                           <CustomInput
                            fieldLabel='Footer Text Color'
                            control={control}
                            error={errors.footer_text_color}
                            fieldName='footer_text_color'
                            type={"color"}
                        />
                         <CustomInput
                            fieldLabel='Footer  Color'
                            control={control}
                            error={errors.footer_color}
                            fieldName='footer_color'
                            type={"color"}
                        />
                    </Row>
                    <div >
                        <img src={`${datalist?.image}${datalist?.logo}`} style={{ width: "80px", height: "80px" }}></img>
                    </div>
                    <ImagesInput
                        fieldLabel='Image :'
                        control={control}
                        error={errors.image}
                        fieldName='image'
                        type='file'
                        onChangeValue={(file) => setValue("image", file)}
                    />
                    {/* <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                        <h5
                            style={{ marginTop: 10, fontSize: 12 }}
                        >
                            Enable delivery slot
                        </h5>
                        <Input
                            checked={itemSwitch}
                            onChange={(e) => setItemSwitch(e.target.checked)}
                        type="checkbox"

                        />
                    </div> */}
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    color="primary"
                    onClick={handleSubmit(onsubmit)}
                >
                    Save
                </Button>
                <Button
                    type="button"
                    color="secondary"
                    onClick={onCloseModal}
                >
                    Close
                </Button>
            </ModalFooter>
        </Modal>

    )
}

export default ProfileModal