import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp, FirebaseApp } from 'firebase/app';
import customAxios from "../customAxios";
// import { messagings } from "../../public/firebase-messaging-sw";
var firebaseConfig = {
    apiKey: "AIzaSyCsjsarTFm2VXudEMFiW8_5zk9VNV_PZvQ",
    authDomain: "dgcart-ae937.firebaseapp.com",
    projectId: "dgcart-ae937",
    storageBucket: "dgcart-ae937.appspot.com",
    messagingSenderId: "718331392984",
    appId: "1:718331392984:web:696e760460ab9c958bb8ad"
};

initializeApp(firebaseConfig);
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);



export const FCMToken = async (setTokenFound) => {
    try {
        const currentToken = await getToken(messaging, { vapidKey: 'BKFaVUJOeFn37-kJLSjfK6A_8PGwVpkUg6zB3AHYdYAtC62n8Ps-EamHc7tNUiIsMcl4P5xudrwXCiWh3fa2ERM' });
        if (currentToken) {
            try {
                const payload = { token: currentToken }
                await customAxios.post('admin/updatetoken',payload)
            } catch (err) {

            }
            setTokenFound(true);
        } else {
            // console.log('No registration token available. Request permission to generate one.');
            setTokenFound(false);
        }
    } catch (err) {
        // console.log('An error occurred while retrieving token. ', err);
    }
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    }); 