
import React, { Fragment, useState } from "react";
import { User, Unlock } from "react-feather";
import { useNavigate } from "react-router-dom";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import CustomInput from "../common/CustomInput";
import Customotp from "./Customotp";

function Forgotpswd() {

	const [value, setvalue] = useState(false)


	const schema = yup.object({
		Email: yup.string().email().required(),

	}).required();

	const { register, formState: { errors }, handleSubmit, control } = useForm({
		resolver: yupResolver(schema)
	});



	const onSubmit = (data) => console.log(data);
	return (
		<Fragment>
			<div style={{ display: "flex", flexDirection: "column", height: "400px" }} >
				<Form className="form-horizontal " >
					<div style={{ paddingBottom: "15px", direction: "rtl" }}>
						<h1 style={{ fontSize: "22px" }} className="font-ayurdx">? Forget Your Password</h1>
					</div>
					<CustomInput
						fieldName={"Email"}
						placeholder="Type your email"
						error={errors.Email}
						control={control}
					/>
					<div className="form-button pull-right">
						<a href="/#" className="btn btn-default forgot-pass p-0 font-ayurdx" onClick={handleSubmit(onSubmit)}>
							Get Your OTP
						</a>
					</div>
				</Form>
				<div style={{ paddingTop: "50px" }}>

					<Customotp />

				</div>
			</div>
		</Fragment>
	)
}

export default Forgotpswd