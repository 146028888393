import { act } from "@testing-library/react";
import { EDIT_STATUS_FAIL, EDIT_STATUS_SUCCESS, GET_ORDER_FAIL, GET_ORDER_SUCCESS, GET_SINGLE_ORDER_FAIL, GET_SINGLE_ORDER_SUCCESS, GET_STATUS_ORDER_FAIL, GET_STATUS_ORDER_SUCCESS } from "../constants/orderConstants";
import { RESET_ERROR } from "../constants/userConstants"

export const orderReducer = (state = { error: null }, action) => {
    switch (action.type) {
        case RESET_ERROR:
            return {
                ...state,
                error: null,
                orderupdated: null

            }

        case GET_ORDER_SUCCESS:
            return {
                ...state,
                orderList: action.payload
            }
            
        case GET_ORDER_FAIL: {
            return {
                ...state,
                error: action.payload
            }
        }

        case GET_SINGLE_ORDER_SUCCESS:
            return {
                ...state,
                singleorderlist: action.payload
            }

        case GET_SINGLE_ORDER_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case EDIT_STATUS_SUCCESS:
            return {
                ...state,
                orderList: state?.orderList.map(status => status._id === action.payload._id ? action.payload : status),
                orderupdated: true
            }

        case EDIT_STATUS_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case GET_STATUS_ORDER_SUCCESS:
            return {
                ...state,
                orderstatusList: action.payload
            }

        case GET_STATUS_ORDER_FAIL:
            return {
                ...state,
                error: action.payload

            }

        default:
            return state;

    }


}