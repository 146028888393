import React, { Fragment, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Controller } from "react-hook-form";
import CustomInput from "../common/CustomInput";

const Customotp = () => {
    const schema = yup.object({
        otp: yup.string()
            .required("OTP is a required field")
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(6, 'Must be exactly 6 digits')
            .max(6, 'Must be exactly 6 digits'),

    }).required();

    const { register, formState: { errors }, handleSubmit, control } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            otp: ''
        }
    });


    const onSubmitdata = (data) => console.log(data);
    return (
        <>
            <Form className="form-horizontal auth-form">
                <CustomInput
                    fieldName={"otp"}
                    placeholder="Type your OTP"
                    error={errors.otp}
                    control={control}
                    maxLength={"6"}
                />
                <div className="form-button pull-right">
                    <Button
                        color="primary"
                        className="btn-ayurdx"
                        onClick={handleSubmit(onSubmitdata)}                    >
                        Submit</Button>
                </div>
            </Form>
        </>
    )
}

export default Customotp