import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Modal,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import CustomInput from "../../common/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import ImagesInput from "../../common/ImagesInput";
import {
  CREATENOTIFICATION,
  EDITNOTIFICATION,
} from "../../../redux/actions/SettingActions";
import SelectInput from "../../common/SelectInput";
import { toast } from "react-toastify";
const ModalNotification = ({
  openModal,
  onCloseModal,
  data,
  setActiveData,
  product,
  category,
}) => {
  const dispatch = useDispatch();
  const [type, setType] = useState([
    { value: "app", label: "App" },
    { value: "web", label: "Web" },
  ]);
  const { loading } = useSelector((state) => state.auth);

  const {
    notificationcreated,
    notificationfailed,
  } = useSelector((state) => state.product);

  const [type1, setType1] = useState([
    { value: "category", label: "Category" },
    { value: "product", label: "Product" },
  ]);
  const [myType, setMyType] = useState(null);
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
  const schema = yup
    .object({
      name: yup.string().required("Name  is required"),
      description: yup.string().required("Description  is required"),
      // screen: yup.string().required("Screen  is required"),
      // image: yup.mixed()
      //     .nullable()
      //     .required('A file is required')
      //     .test('Fichier taille',
      //         'upload file', (value) => !value || (value && value.size >= 768 * 1000))
      //     .test('format',
      //         'upload file', (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))),
    })
    .required();

  const {
    register,
    handleSubmit,
    setFieldValue,
    control,
    formState: { errors },
    reset,
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      image: [],
      name: "",
      description: "",
      screen: "",
    },
  });
  const clearclose = () => {
    reset();
    setValue("name", "");
    setValue("description", "");
    setValue("screen", "");
    setValue("type", "");
    setActiveData(null);
    onCloseModal();
  };

  const handleSubmitform = (dataform) => {
    if (
      dataform?.type1?.value === "product" &&
      dataform?.product_id === undefined
    ) {
      toast.warn("Product id is Required");
      return false;
    } else if (
      dataform?.type1?.value === "category" &&
      dataform?.category_id === undefined
    ) {
      toast.warn("Category id is Required");
      return false;
    }

    if (data) {
      const formData = new FormData();
      formData.append("id", data?._id);
      formData.append("name", dataform?.name);
      formData.append("description", dataform?.description);
      formData.append("screen", dataform?.screen);
      formData.append("status", "active");
      formData.append("type", dataform.type?.value);
      formData.append("value", "null");
      if (dataform?.image) {
        formData.append("image", dataform?.image);
      }
      dispatch(EDITNOTIFICATION(formData));
      setValue("name", "");
      setValue("description", "");
      formData.append("value", "null");
      setValue("screen", "");
      setActiveData(null);
    } else {
      const formData = new FormData();
      if (dataform?.type1?.value === "product") {
        formData.append("product_id", dataform?.product_id?.value);
      } else {
        formData.append("category_id", dataform?.category_id?.value);
      }
      formData.append("name", dataform?.name);
      formData.append("description", dataform?.description);
      formData.append("screen", dataform?.screen);
      formData.append("image", dataform?.image);
      formData.append("type", dataform.type?.value);
      formData.append("type1", dataform.type1?.value);
      dispatch(CREATENOTIFICATION(formData));
    }
    reset();
    setMyType(null);
  };

  useEffect(() => { 
    if(!loading) {
      onCloseModal()
    }
  }, [loading])

  useEffect(() => {
    if (data) {
      const find = type?.find((res) => res?.value === data?.type);
      setValue("name", data?.name);
      setValue("description", data?.description);
      setValue("screen", data?.screen);
      setValue("type", find);
    }
  }, [data]);

  const onChangeType = (value) => {
    setValue("type1", value);
    setError("type1", "");
    setMyType(value);
  };

  return (
    <Modal isOpen={openModal} toggle={loading ? null : () => clearclose()}>
      <ModalHeader toggle={loading ? null : clearclose}>
        <h5 className="modal-title f-w-600" id="exampleModalLabel2">
          Add Notification
        </h5>
      </ModalHeader>
      <ModalBody>
        <Form>
          <CustomInput
            fieldLabel="Notification Name :"
            control={control}
            error={errors.name}
            fieldName="name"
          />
          <CustomInput
            fieldLabel="Description :"
            control={control}
            error={errors.description}
            fieldName="description"
          />
          <SelectInput
            fieldLabel="Type"
            control={control}
            error={errors.type}
            fieldName="type"
            options={type}
          />
          <SelectInput
            selectvalue={myType}
            onChangeValue={onChangeType}
            fieldLabel="Type Based On"
            control={control}
            error={errors.type1}
            fieldName="type1"
            options={type1}
          />
          {myType && myType?.value === "product" && (
            <SelectInput
              fieldLabel="Product"
              control={control}
              error={errors.product_id}
              fieldName="product_id"
              options={product}
            />
          )}
          {myType && myType?.value === "category" && (
            <SelectInput
              fieldLabel="Category"
              control={control}
              error={errors.category_id}
              fieldName="category_id"
              options={category}
            />
          )}

          {/* <CustomInput
                        fieldLabel='Page Url :'
                        control={ control }
                        error={ errors.screen }
                        fieldName='screen'
                    /> */}

          {data ? (
            <div>
              <img
                src={data?.image}
                alt={"images"}
                style={{ width: "80px", height: "80px" }}
              ></img>
            </div>
          ) : (
            ""
          )}
          <ImagesInput
            fieldLabel="Image :"
            control={control}
            error={errors.image}
            fieldName="image"
            type="file"
            onChangeValue={(file) => setValue("image", file)}
          />
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          color="primary"
          onClick={handleSubmit(handleSubmitform)}
        >
          {loading ? <Spinner size={'sm'}>loading...</Spinner> : "Save"}
        </Button>
        {/* <Button
                    type="button"
                    color="secondary"
                    onClick={() => onCloseModal(
                        setValue("name", ""),
                        setValue("description", ""),
                        setValue("screen", ""),
                        setActiveData(null)

                    )}
                >
                    Close
                </Button> */}
      </ModalFooter>
    </Modal>
  );
};

export default ModalNotification;
