import React, { Fragment, useEffect, useState } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User, Unlock } from "react-feather";
import { useNavigate } from "react-router-dom";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import CustomInput from "../common/CustomInput";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { RESET_ERROR } from "../../redux/constants/userConstants";
import { loginUser } from "../../redux/actions/userActions";

const LoginTabset = () => {
	const history = useNavigate();

	const { loginSuccess, loading, error } = useSelector(state => state.auth)

	const [authLoading, setAuthLoading] = useState(true)

	const dispatch = useDispatch();
	const schema = yup.object({
		email: yup.string().email().required('Email is required'),
		password: yup.string()
			.required('No password provided.')
			.min(6, 'Password is too short - should be 6 characters minimum.')
		// .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.')

	}).required()

	const { register, formState: { errors }, handleSubmit, control } = useForm({
		resolver: yupResolver(schema),
		defaultValues:{
			role: "admin"
		}
	});


	useEffect(() => {
		if (loginSuccess) {
			//navigate to dashboard
			history(`${process.env.PUBLIC_URL}/orders`);
			dispatch({
				type: RESET_ERROR
			})
		}

		if (error) {
			//using toast display error message
			dispatch({
				type: RESET_ERROR
			})
		}
	}, [loginSuccess, error])

	useEffect(() => {
		checkLogin()
	}, [])

	const checkLogin = async () => {
		let token = await localStorage.getItem("token");

		if (token) {
			history(`${process.env.PUBLIC_URL}/orders`);
		}
		else {
			setAuthLoading(false)
		}
	}


	const onSubmit = (data) => {
		// console.log(data, "user information")
		dispatch(loginUser(data))
	};

	const clickActive = (event) => {
		document.querySelector(".nav-link").classList.remove("show");
		event.target.classList.add("show");
	};

	const routeChange = (data) => {
		// history(`${process.env.PUBLIC_URL}/dashboard`);



	};
	const routeChangeforgotpswd = () => {
		history(`/auth/forgot`);
	};

	if (authLoading) {
		return (
			<div>Loading...</div>
		)
	}
	else {
		return (
			<div>
				<Fragment>

					<div style={{ display: "flex", flexDirection: "column", height: "400px" }} >


						<Form className="form-horizontal auth-form">

							<div style={{ display: "flex", alignItems: "center", gap: "5px", paddingBottom: "30px", }} >
								<User />
								<h1 style={{ fontSize: "20px", padding: "0px", margin: "0px", color: '#fddc4f' }}>Login</h1>
							</div>

							<CustomInput
								fieldName={"email"}
								placeholder="Email"
								error={errors.email}
								control={control}
								type={"email"}
							/>
							<CustomInput
								fieldName={"password"}
								placeholder="Password"
								error={errors.password}
								control={control}
								type={"password"}
							/>
							<div className="form-terms">
								<div className="custom-control custom-checkbox me-sm-2">
									<Label className="d-block">
										<Input
											className="checkbox_animated"
											id="chk-ani2"
											type="checkbox"
										/>
										Remember Me{" "}
										<span className="pull-right">
											{" "}
											<a className="btn btn-default forgot-pass p-0" style={{ color: '#fddc4f' }} onClick={() => routeChangeforgotpswd()}>
												lost your password
											</a>
										</span>
									</Label>
								</div>
							</div>
							<div className="form-button" style={{ paddingTop: "50px" }}>
								<Button
									type="submit"
									className="btn-ayurdx"
									disabled={loading}
									//onClick={() => routeChange()}
									onClick={handleSubmit(onSubmit)}

								>
									{loading ? 'Loading...' : 'Login'}
								</Button>
							</div>

						</Form>

					</div>

				</Fragment>
			</div>
		);
	}

};

export default LoginTabset;
