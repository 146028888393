// SEditor.jsx

import React from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

const SEditor = ({ value, onChange, error, disabled }) => {
  return (
    <>
      <SunEditor
        setOptions={{
          height: 200,
          buttonList: [['bold', 'underline', 'italic', 'strike'], ['list']],
        }}
        onChange={onChange}
        defaultValue={value}
        disable={disabled}
      />
      {error && <span className="text-danger">{error.message}</span>}
    </>
  );
};

export default SEditor;
