import unitdata from "../../components/products/unit/unitdata";
import {
    GET_UNIT,
    GET_UNIT_FAIL,
    GET_UNIT_SUCCESS,
    CREATE_UNIT_SUCCESS,
    CREATE_UNIT_FAILER,
    UNIT_DEL_SUCCESS,
    UNIT_DEL_FAILER,
    UNIT_EDIT_SUCCESS,
    UNIT_EDIT_FAIL,
    CREATE_CATEGORY_SUCCESS,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORY_FAIL,
    DEL_CATEGORY_SUCCESS,
    EDIT_CATEGORY_SUCCESS,
    EDIT_CATEGORY_FAIL,
    CREATE_SUBCATEGORY_SUCCESS,
    GET_SUBCATEGORY_SUCCESS,
    GET_SUBCATEGORY_FAIL,
    CREATE_SUBCATEGORY_FAIL,
    DEL_SUBCATEGORY_SUCCESS,
    DEL_SUBCATEGORY_FAIL,
    EDIT_SUBCATEGORY_SUCCESS,
    EDIT_SUBCATEGORY_FAIL,
    GET_SUBCATEGORY_ID_SUCCESS,
    GET_SUBCATEGORY_ID_FAIL,
    GET_FEATURED_SUCCESS,
    GET_FEATURED_FAIL,
    CREATE_FEATURE_SUCCESS,
    CREATE_FEATURE_FAIL,
    DEL_FEATURE_SUCCESS,
    DEL_FEATURE_FAIL,
    EDIT_FEATURE_SUCCESS,
    EDIT_FEATURE_FAIL,
    CREATE_PRODUCT_SUCCESS,
    CREATE_PRODUCT_FAIL,
    GET_PRODUCT_SUCCESS,
    GET_PRODUCT_FAIL,
    DEL_PRODUCT_SUCCESS,
    DEL_PRODUCT_FAIL,
    GET_SINGLE_PROD_SUCCESS,
    GET_SINGLE_PROD_FAILURE,
    EDIT_PRODUCT_PRICE_SUCCESS,
    EDIT_PRODUCT_PRICE_FAIL,
    EDIT_PRODUCT_SUCCESS,
    EDIT_PRODUCT_FAIL,
    CREATE_CATEGORY_FAIL,
    DEL_CATEGORY_FAIL,
    TOPICS
} from "../constants/productConstants";
import { RESET_ERROR } from "../constants/userConstants";
import toast from 'react-toastify'


export const productReducer = (state = { error: null }, action) => {
    switch (action.type) {

        case RESET_ERROR:
            return {
                ...state,
                error: null,
                newUnit: null,
                unitDeleteSuccess: null,
                updatedunit: null,
                newcategory: null,
                categorydelsuccess: null,
                editedcategory: null,
                newsubcategory: null,
                subcategorydelsuccess: null,
                subcategoryedited: null,
                subcategorybyid: null,
                featuredcreated: null,
                featuredeleted: null,
                updatedfeature: null,
                productdeleted:null,
                productcreated:null,
                productpriceupdated:null,
                productupdatedD:null
            }

        case GET_UNIT_SUCCESS:
            //console.log({ unitred: action.payload })
            return {
                ...state,
                unitList: action.payload
            }

        case GET_UNIT_FAIL:
        case CREATE_CATEGORY_FAIL:
        case DEL_CATEGORY_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case CREATE_UNIT_SUCCESS:
            return {
                ...state,
                unitList: state?.unitList ? [...state.unitList, action.payload] : [action.payload],
                newUnit: true
            }
        case CREATE_UNIT_FAILER:
            return {
                ...state,
                error: action.payload
            }

        case UNIT_DEL_SUCCESS:
            return {
                ...state,
                unitList: state?.unitList.filter(unit => unit._id !== action.payload),
                unitDeleteSuccess: true,
            }
            
        case UNIT_DEL_FAILER:
            return {
                ...state,
                error: action.payload

            }


        case UNIT_EDIT_SUCCESS:

            return {
                ...state,
                unitList: state?.unitList.map(unit => unit._id === action.payload._id ? action.payload : unit),
                updatedunit: true,
            }
        case UNIT_EDIT_FAIL:
            return {
                ...state,
                error: action.payload
            }

        //....................................CATEGORY...............................

        case GET_CATEGORY_SUCCESS:
            return {
                ...state,
                getcatogrylist: action.payload
            }
        case GET_CATEGORY_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case CREATE_CATEGORY_SUCCESS:
            return {
                ...state,
                getcatogrylist: state?.getcatogrylist ? [...state.getcatogrylist, action.payload] : [action.payload],
                newcategory: true

            }
        case DEL_CATEGORY_SUCCESS:
            return {
                ...state,
                getcatogrylist: state?.getcatogrylist.filter(unit => unit._id !== action.payload),
                categorydelsuccess: true,
            }

        case EDIT_CATEGORY_SUCCESS:
            return {
                ...state,
                getcatogrylist: state?.getcatogrylist.map(cate => cate._id === action.payload._id ? action.payload : cate),
                editedcategory: true
            }
        case EDIT_CATEGORY_FAIL:
            return {
                ...state,
                error: action.payload
            }

        //....................................SUB_CATEGORY................................................................  

        case GET_SUBCATEGORY_SUCCESS:
            return {
                ...state,
                getsubcategoryList: action.payload
            }

        case GET_SUBCATEGORY_FAIL:
            return {
                ...state,
                error: action.payload,

            }

        case CREATE_SUBCATEGORY_SUCCESS:
            return {
                ...state,
                getsubcategoryList: state?.getsubcategoryList ? [...state.getsubcategoryList, action.payload] : [action.payload],
                newsubcategory: true
            }

        case CREATE_SUBCATEGORY_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_SUBCATEGORY_ID_SUCCESS:
            return {
                ...state,
                subcategorylistbyid: action.payload,
                subcategorybyid: true
            }
        case GET_SUBCATEGORY_ID_FAIL:
            return {
                ...state,
                error: action.payload,
                subcategorylistbyid:[]
                
            }

        case DEL_SUBCATEGORY_SUCCESS:
            return {
                ...state,
                getsubcategoryList: state?.getsubcategoryList.filter(unit => unit._id !== action.payload),
                subcategorydelsuccess: true
            }
        case DEL_SUBCATEGORY_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case EDIT_SUBCATEGORY_SUCCESS:
            return {
                ...state,
                getsubcategoryList: state?.getsubcategoryList.map(sub => sub._id === action.payload._id ? action.payload : sub),
                subcategoryedited: true,
            }
        case EDIT_SUBCATEGORY_FAIL:
            return {
                ...state,
                error: action.payload
            }
        //*********************************ADDPRODUCT******************************************************************** */ 

        case GET_PRODUCT_SUCCESS:
            
            return {
                ...state,
                productlist: action.payload?.data
            }
        case GET_PRODUCT_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case CREATE_PRODUCT_SUCCESS:
            return {
                ...state,
                productlist: state?.productlist ? [...state.productlist, action.payload] : [action.payload],
                productcreated:true
            }
        case CREATE_PRODUCT_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case DEL_PRODUCT_SUCCESS:
            return{
                ...state,
                productlist:state?.productlist.filter(unit => unit._id !== action.payload),
                productdeleted:true,
            } 
        case DEL_PRODUCT_FAIL:
            return{
                ...state,
                error:action.payload
            }   
        case GET_SINGLE_PROD_SUCCESS:
            return{
                ...state,
                singleproductList:action.payload
            }
        case TOPICS: 
            return {
                ...state,
                productTopics: action.payload
            }
        case GET_SINGLE_PROD_FAILURE:
            return{
                ...state,
                error:action.payload
            }    
        
        case EDIT_PRODUCT_PRICE_SUCCESS:
            return{
                ...state,
                singleproductList:action.payload,
                productpriceupdated:true,

            }  
        case EDIT_PRODUCT_PRICE_FAIL:
            return{
                ...state,
                error:action.payload
            }      

        case EDIT_PRODUCT_SUCCESS:

            return{
                ...state,
                singleproductList:action?.payload,
                productupdatedD:true
            } 
        
        case EDIT_PRODUCT_FAIL:
            return{
                ...state,
                error:action.payload
            }    


        //*******************FEATURED******************************************************************************************* */   

        case GET_FEATURED_SUCCESS:
            return {
                ...state,
                featuredList: action.payload
            }
            
        case GET_FEATURED_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case CREATE_FEATURE_SUCCESS:
            return {
                ...state,
                featuredList: state?.featuredList ? [...state.featuredList, action.payload,] : [action.payload],
                featuredcreated: true
            }

        case CREATE_FEATURE_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case DEL_FEATURE_SUCCESS:
            return {
                ...state,
                featuredList: state?.featuredList.filter(feature => feature._id !== action.payload),
                featuredeleted: true,
            }

        case DEL_FEATURE_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case EDIT_FEATURE_SUCCESS:
            return {
                ...state,
                featuredList: state?.featuredList.map(feture => feture._id === action.payload._id ? action.payload : feture),
                updatedfeature: true,

            }
        case EDIT_FEATURE_FAIL:
            return {
                ...state,
                error: action.payload
            }

        default:
            return state;
    }

}