import axios from "../../customAxios"
import {
    LOADING, LOGIN_FAILED, LOGIN_SUCCESS
} from "../constants/userConstants"

export const loginUser = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`auth/login`, data)
        .then(async response => {

            let data = response.data.user;

            await localStorage.setItem("user", JSON.stringify(data));
            await localStorage.setItem("token", response.data.access_token);

            dispatch({
                type: LOGIN_SUCCESS,
                payload: data
            });

            dispatch({
                type: LOADING,
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type: LOGIN_FAILED,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
        })
}
