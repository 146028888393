import React, { useState, useEffect } from 'react'
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CustomTextArea from '../common/CustomTextArea';
import ImageInput from '../common/ImageInput';
import CustomInput from '../common/CustomInput';
import ImagesInput from '../common/ImagesInput';
import axios from 'axios';
import customAxios from '../../customAxios';
import { toast } from 'react-toastify';
import SelectInput from '../common/SelectInput';
import Reactotron from "reactotron-react-js"
import SEditor from '../SEditor/SunEditor';

const ViewBlog = ({ openModal, onCloseModal, id }) => {
    
   const [loading, setLoading] = useState(false)

   const [blogDetails, setBlogDetails] = useState({})
   const [description, setDescription] = useState("");

   const [topics, setTopics] = useState([]);

   console.log(description,"des")

    useEffect(() => {
        getBlogDetails(id)

        return () => {
            setDescription(null)
            setBlogDetails(null)
        }
    }, [id])

    const { register, handleSubmit, setFieldValue, control, formState: { errors }, reset, setValue, getValues } = useForm();


    const getBlogDetails = async(id) => {

        setDescription('')

        setLoading(true)
        let blog = await customAxios.get(`admin/blog/${id}`);

        let datas = blog?.data?.data
        setBlogDetails(datas)

        let data = {
            title: datas?.title,
            description: datas?.description,
            image: datas?.image,
            videourl: datas?.videourl,
            topic: datas?.topics,
            detail_description: datas?.detail_description
        }

        setDescription(datas?.detail_description)


        // console.log({data})

        reset(data)
    }

    const getBlogTopics = async () => {
        try {
            setLoading(true)
            let topics = await customAxios.get(`admin/topics`);

            const items = topics?.data?.data?.map(({ name, _id }) => ({
                label: name,
                value: _id
            }))

            setTopics(items)

        } catch (error) {
            toast.error(error)
        }
        finally {
            setLoading(false)
        }
    }


    useEffect(() => {
        getBlogTopics();

        return () => {
            setBlogDetails(null)
            reset({
                title: "",
                description: "",
                image: null,
                videourl: "",
                topic:""
            })
        }
    }, [])
    

    const clearclose = () => {
         reset({
            title: "",
            description: "",
            image: null,
            videourl: "",
            topic:""
        })
        setDescription(null)
        onCloseModal()
        setValue("name", "")
    }

    return (

        <Modal isOpen={openModal} toggle={clearclose}>
            <ModalHeader toggle={clearclose}>
                <h5
                    className="modal-title f-w-600"
                    id="exampleModalLabel2"
                >
                    View Blog
                </h5>
            </ModalHeader>
            <ModalBody>
                <Form>
                    <CustomInput
                        fieldLabel='Title :'
                        control={control}
                        error={errors.title}
                        fieldName='title'
                        readOnly={true}
                    />
                    <CustomTextArea
                        fieldLabel='Description :'
                        control={control}
                        error={errors.description}
                        fieldName='description'
                        type="textarea"
                        readOnly={true}
                        defaultValue={blogDetails?.description}
                    />
                    {description && <SEditor
                        value={description}
                        onChange={(content) => {
                            setValue('detail_description', content)
                            setDescription(content)
                        }}
                        error={errors.description}
                        disabled={true}
                        
                    />}
                    <img 
                        src={blogDetails?.image}
                        style={{width: "300px", height: "auto", objectFit: "cover"}}
                    />
                    <CustomInput
                        fieldLabel='Video URL:'
                        control={control}
                        error={errors.videourl}
                        fieldName='videourl'
                        readOnly={true}
                    />
                     <SelectInput 
                        fieldLabel='Blog Topics'
                        control={control}
                        error={errors.topic}
                        fieldName='topic'
                        options={topics}
                        isMultiple
                        // onChangeValue={onChangeValue}
                        disabled
                    />
                </Form>
            </ModalBody>
        </Modal>

    )
}

export default ViewBlog
