import React, { useState, useEffect } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User, Settings } from "react-feather";
import { Button, Col, Input, Label, Row, Table } from "reactstrap";
import ProfileModal from "./ProfileModal";
import { useDispatch, useSelector } from "react-redux";
import { GETPROFILE } from "../../../redux/actions/SettingActions";
import { toast } from 'react-toastify';
import { RESET_ERROR } from '../../../redux/constants/userConstants';
import Logo from '../../../assets/images/dashboard/stats.png'

const TabsetProfile = () => {
	const { profilecreated, profilelist, error, profileupdated } = useSelector(state => state.settings);
	

	const dispatch = useDispatch()

	console.log({ profilelist });


	
	const [open, setOpen] = useState(false);
	const [edit, setedit] = useState(false)

	const onOpenModal = () => {
           
		setOpen(true);
	};

	const onCloseModal = () => {
		setOpen(false);
	};



	useEffect(() => {
		dispatch(GETPROFILE()) 
	}, [open])


	useEffect(() => {
		if (profileupdated) {
			toast.success("Profile updated successfully");
			dispatch({
				type: RESET_ERROR
			})
			onCloseModal()
		}
	}, [profileupdated])
	useEffect(() => {
		if (error) {
			toast.warning(`${error.message}`);
			dispatch({
				type: RESET_ERROR
			})
		}
	}, [error])
	return (
		<div>
			<div style={{ display: "flex", justifyContent: "flex-end" }}><Button onClick={() => (
				onOpenModal(),
				setedit(true)
			)}>EDIT PROFILE</Button></div>


			<div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
				<div>
					<img src={(profilelist?.image && profilelist?.logo) ? `${profilelist?.image}${profilelist?.logo}`: Logo} alt="image" style={{ width: "100px", height: "100px" }} ></img>
				</div>
				{/* <h1 style={{fontSize:"18px"}}>profile</h1> */}
			</div>





			<div className="tab-pane fade show active">
				<h5 className="f-w-600 f-16">Profile</h5>
				<div className="table-responsive profile-table">
					<Table className="table-responsive">
						<tbody>
							<tr>
								<td>Store Name:</td>
								<td>{profilelist?.storename}</td>
							</tr>
							<tr>
								<td>Store Email:</td>
								<td>{profilelist?.storeemail}</td>
							</tr>
							<tr>
								<td>Address:</td>
								<td>{profilelist?.address}</td>
							</tr>
							<tr>
								<td>Mobile:</td>
								<td>{profilelist?.mobile}</td>
							</tr>
							<tr>
								<td>GST(%):</td>
								<td>{profilelist?.gst}</td>
							</tr>
							<tr>
								<td>Delivery Charge:</td>
								<td>{profilelist?.delivery_charge}</td>
							</tr>
							<tr>
								<td>Free Delivery Amount:</td>
								<td>{profilelist?.freedeliveryvalue}</td>
							</tr>
							{/* <tr>
								<td>Delivery Slot:</td>
								<td>{profilelist?.delivery_slot === 1 ? 'Enabled' : 'Disabled'}</td>
							</tr> */}
							<tr>
								<td>Primary Color:</td>
								<input type="color" id="favcolor" name="favcolor" readOnly disabled value={profilelist?.primary_color} /> 
							</tr>
							<tr>
								<td>Secondary Color:</td>
								<input type="color" id="favcolor" name="favcolor" readOnly disabled value={profilelist?.secondary_color} />
							</tr>
							<tr>
								<td>Tertiary Color:</td>
								<input type="color" id="favcolor" name="favcolor" readOnly disabled value={profilelist?.tertiary_color}/>
							</tr>
							<tr>
								<td>GSTIN:</td>
								<td>{profilelist?.gstin}</td>
							</tr>
							<tr>
								<td>Whatsapp link:</td>
								<td>{profilelist?.whatsapp_link}</td>
							</tr>
							<tr>
								<td>Facebook link:</td>
								<td>{profilelist?.facebook_link}</td>
							</tr>
							<tr>
								<td>Instagram link:</td>
								<td>{profilelist?.instagram_link}</td>
							</tr>
							<tr>
								<td>Linkedin link:</td>
								<td>{profilelist?.linkedin_link}</td>
							</tr>
							<tr>
								<td>Booking link:</td>
								<td>{profilelist?.booking_link}</td>
							</tr>
							{/* <tr>
								<td>State:</td>
								<td>{profilelist?.state}</td>
							</tr> */}
							<tr>
								<td>HSN Code:</td>
								{/* <input type="color" id="favcolor" readOnly disabled name="favcolor" value={profilelist?.hsn_code} /> */}
								<td>{profilelist?.hsn_code}</td>
							</tr>
							{/* <tr>
								<td>DOB:</td>
								<td>Dec, 15 1993</td>
							</tr>
							<tr>
								<td>Location:</td>
								<td>USA</td>
							</tr> */}
						</tbody>
					</Table>
				</div>
			</div>
			<ProfileModal openModal={open} onCloseModal={() => {
				setOpen(false)
			}} datalist={profilelist} error={error} />

		</div>
	);
};

export default TabsetProfile;
