
import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import DataTables from "../common/DataTables";
import { useDispatch, useSelector } from "react-redux";
import { GetCustomerList } from "../../redux/actions/SettingActions";
import Reactotron from "reactotron-react-js"
import SearchHeader from "../common/header_components/searchHeader";
import DataTable from "react-data-table-component";

const CustomerList = () => {
    const dispatch =useDispatch()
    

    const { CustomerListdata } = useSelector(state => state.settings)
    Reactotron.log(CustomerListdata,"CustomerListdata")


    const [filterText, setFilterText] = useState('');
    const [datas, setDatas] = useState([])


    useEffect(() => {
        if (CustomerListdata) {
            setDatas(CustomerListdata)
            // setSearch(orderList)
        }
    }, [CustomerListdata])

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    var columns = [
        {
            name: <b>{Capitalize("S.No")}</b>,
            header: <b>{Capitalize("S.No")}</b>,
            cell: (row, index) => index + 1
        },
        {
            name: <b>{Capitalize("Name")}</b>,
            header: <b>{Capitalize("Name")}</b>,
            selector: row => row?.name,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("Email")}</b>,
            header: <b>{Capitalize("Email")}</b>,
            selector: row => <h6 title={row?.email}>{row?.email}</h6>,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("Address")}</b>,
            header: <b>{Capitalize("Address")}</b>,
            cell: (row, index) => (
      
                <>
                    <span>
                     
                         {row?.address?.area?.address ? `${row?.address?.area?.address}  ${row?.address?.pincode ? ',': ''}  ${row?.address?.pincode ? row?.address?.pincode : ''}` : '-'}
                         {/* {row?.address?.addressLine2},
                         {row?.address?.country},
                         {row?.address?.city},
                         {row?.address?.state},
                         {row?.address?.zip}, */}

                    </span>
                </>

            )
        },
        {
            name: <b>{Capitalize("Mobile")}</b>,
            header: <b>{Capitalize("Mobile")}</b>,
            selector: row => row?.mobile,
            style: {
                textAlign: "center",
            },
        },
   
        {
            name: <b>{Capitalize("status")}</b>,
            header: <b>{Capitalize("status")}</b>,
            selector: row => row?.status,
            style: {
                textAlign: "center",
            },
        },
       
    ];
    

useEffect(() => {
 dispatch(GetCustomerList())
}, [])

const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
        if (filterText) {
            setFilterText('');
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
            <SearchHeader onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        </div>
    );
}, [filterText]);

useEffect(() => {
    // if(filterText){
        let datas = CustomerListdata?.filter(cat => cat?.name?.toLowerCase().includes(filterText?.toLowerCase()) || cat?.email?.toLowerCase().includes(filterText?.toLowerCase()) || cat?.mobile?.toLowerCase().includes(filterText?.toLowerCase()))
        console.log({datas})
        setDatas(datas)
    // }
}, [filterText])

  return (
    <Fragment>
            <Breadcrumb title="Customer" />
            {/* <!-- Container-fluid starts--> */}
            <Container fluid="true">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <h5>Customer List</h5>
                            </CardHeader>
                            <CardBody>
                                
                                <div className="clearfix"></div>
                                <div id="basicScenario" className="product-physical">
                                    {CustomerListdata && <DataTable
                                       pagination
                                        columns={columns}
                                        //row={CustomerListdata ? datas : []}
                                        data={datas}
                                        fixedHeader  
                                        subHeader
                                        subHeaderComponent={subHeaderComponentMemo} 
                                    />}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
           
        </Fragment>
  )
}

export default CustomerList