// import React from 'react'

// const Holidays = () => {
//   return (
//     <div>Holidays</div>
//   )
// }

// export default Holidays

import React, { Fragment, useEffect, useState } from 'react'
import { Breadcrumb, Card, Col, Container, CardBody, Button, CardHeader, Row } from 'reactstrap'
import DataTable from "react-data-table-component";
import customAxios from '../../../customAxios';
import {  toast } from "react-toastify";
import AddHolidaySlotModal from './AddHolidaySlotModal';


const Holidays = () => {
    const [datas, setDatas] = useState([])
    const [open, setOpen] = useState(false);


    const getList = async () => {
        try {
            const response = await customAxios.get('admin/delivery-leave')
            setDatas(response?.data?.data)
        } catch (err) {
            toast.error(err?.message);
        } finally {

        }
    }


    useEffect(() => {
        getList()
    }, [])




    const onOpenModal = () => {

        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };


    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you wish to delete this item?")) {
            try {
                await customAxios.delete(`admin/delivery-leave/${id}`)
                const deletes = datas.filter((res) => res._id !== id);

                setDatas(deletes)
                toast.success(`Deleted Successfully`);

            } catch (err) {

            } finally {

            }
        }


    }
    var columns = [
        {
            name: <b>{Capitalize("S.No")}</b>,
            header: <b>{Capitalize("S.No")}</b>,
            cell: (row, index) => index + 1
        },

      

        {
            name: <b>{Capitalize("Holiday")}</b>,
            header: <b>{Capitalize("Holiday")}</b>,
            selector: row => row?.date,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("Comment")}</b>,
            header: <b>{Capitalize("Comment")}</b>,
            selector: row => row?.comment,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>Actions</b>,
            id: "delete",
            accessor: (str) => "delete",
            cell: (row, index) => (

                <>
                    <span onClick={() => handleDelete(row._id)} >

                        <i
                            className="fa fa-trash"
                            style={{
                                width: 35,
                                fontSize: 20,
                                padding: 11,
                                color: "#e4566e",
                                cursor: "pointer"
                            }}
                        ></i>
                    </span>

                </>
            )
        }
    ];


    return (
        <Fragment>
            <Breadcrumb title="Holyday" parent="Settings" />
            {/* <!-- Container-fluid starts--> */}
            <Container fluid="true">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <h5>Holiday</h5>
                            </CardHeader>
                            <CardBody>
                                <div className="btn-popup pull-right">
                                    <Button
                                        type="button"
                                        color="secondary"
                                        onClick={onOpenModal}
                                        data-toggle="modal"
                                        data-original-title="test"
                                        data-target="#exampleModal"

                                    >
                                        Add Holiday

                                    </Button>

                                </div>
                                <div className="clearfix"></div>
                                <div id="basicScenario" className="product-physical">
                                    <DataTable
                                        pagination
                                        columns={columns}
                                        data={datas}
                                        fixedHeader
                                        subHeader
                                    // subHeaderComponent={subHeaderComponentMemo}  
                                    />

                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <AddHolidaySlotModal
                refetch={getList}
                openModal={open}
                onCloseModal={() => {
                    setOpen(false)
                }}
                title={'Add Time Slot'}
            />


        </Fragment>
    )
}

export default Holidays