
import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import CustomInput from '../../common/CustomInput';
import { useDispatch, useSelector } from "react-redux";
import ImagesInput from '../../common/ImagesInput';
import { object, string, mixed } from "yup"
import { PostSlider, UpdateSlider } from '../../../redux/actions/SettingActions';
import { toast } from 'react-toastify';

const ModalSlider = ({ openModal, onCloseModal, data }) => {
	const dispatch = useDispatch();
	const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
	const [image, setImage] = useState(null)
	const schema = object().shape({
		image: image && yup.mixed()
		.required( "Image resolution should be 1920px * 810px", (file) => {
		  // return file && file.size <-- u can use this if you don't want to allow empty files to be uploaded;
		  if (file) return true;
		  return false;
		})
		.test("fileSize", "The file is too large", (file) => {
		  //if u want to allow only certain file sizes
		  return file && file.size <= 2000000;
		}),
		order: yup.string().required("Order  is Required")
	}).required()

	

    const { sliderlistcreated } = useSelector(state => state.settings)


	const { register, handleSubmit, setFieldValue, control, formState: { errors }, reset, setValue } = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			image: null,
			name: '',
			order: ''
		}
	});

	
	

	useEffect(() => {
	  if(sliderlistcreated){
		reset()
		setImage(null)
	  }

	  return () => {
		reset()
		setImage(null)
	  }
	}, [sliderlistcreated])
	


	const submitform = (datas) => {

		if (data) {
			const formData = new FormData();
			formData.append('id', data?._id);
			formData.append('status', data?.status);
			formData.append('name', datas?.name === "" ? data?.name : datas?.name);
			formData.append('order', datas?.order === "" ? data?.order : datas?.order);
			if (datas?.image) {
				formData.append('image', datas?.image);
			}
			dispatch(UpdateSlider(formData))

		} else {

			if(!image){
				toast.warn('Image is Required');
				return false;
			}
			const formData = new FormData();
			formData.append('name', datas?.name);
			formData.append('order', datas?.order);
			formData.append('image', datas?.image);
			dispatch(PostSlider(formData))
		}


	}

	useEffect(() => {
		if (data) {
			setValue("name", data?.name)
			setValue("order", data?.order)
			// setValue("image", data?.image)
		}
	}, [data])
	const cleardata = () => {
		reset()
		setImage(null)
		onCloseModal()

	}

	const onImgLoad = ({ target: img }) => {
		if (img.offsetWidth === 1920 && img.offsetHeight === 810) {
			setValue("image", image)
		}
		else {
			setValue("image", null)
		}
	}



	return (
		<Modal isOpen={openModal} toggle={cleardata}>
			<ModalHeader toggle={cleardata}>
				<h5
					className="modal-title f-w-600"
					id="exampleModalLabel2"
				>
					{data ? 'Edit Slider' : 'Add Slider'}
				</h5>
			</ModalHeader>
			<ModalBody>
				<Form>
					<CustomInput
						fieldLabel='Slider Name :'
						control={control}
						error={errors.name}
						fieldName='name'
					/>
					<CustomInput
						fieldLabel='Order :'
						control={control}
						error={errors.order}
						fieldName='order'
						type={"number"}
						// disabled={data ? da : false}
					/>
					{/* <span style={{ color: "red" }}>Image resolution should be 768px * 1000px</span> */}
					<div><img src={data?.image} style={{ width: "100px", width: "100px" }}></img></div>
					<ImagesInput
						fieldLabel='Image (1920px * 810px) :'
						control={control}
						error={errors.image}
						fieldName='image'
						type='file'
						onChangeValue={(file) => setImage(file)}
					/>


				</Form>
			</ModalBody>
			<ModalFooter>
				<Button
					type="button"
					color="primary"

					onClick={handleSubmit(submitform)}
				>
					Save
				</Button>
				{/* <Button
					type="button"
					color="secondary"
					onClick={() => onCloseModal(
					)}
				>
					Close
				</Button> */}
			</ModalFooter>
			{image && <div style={{ height: 1, opacity: 0 }} ><img onLoad={onImgLoad} src={URL.createObjectURL(image)} /></div>}
		</Modal>
	)
}

export default ModalSlider