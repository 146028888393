
export const GET_UNIT_SUCCESS = 'GETUNITSUCCESS'
export const GET_UNIT_FAIL = 'GETUNITFAIL'
export const CREATE_UNIT_SUCCESS = "CREATEUNITSUCCESS"
export const CREATE_UNIT_FAILER = "CREATEUNITFAILER"
export const UNIT_DEL_FAILER = "UNITDELETEFAIL"
export const UNIT_DEL_SUCCESS = "UNITDELETESUCCESS"
export const UNIT_EDIT_SUCCESS = "UNITEDITSUCCESS"
export const UNIT_EDIT_FAIL = "UNITEDITFAIL"
export const TOPICS = 'TOPICS'

//*>>>>>>>>>>>>>>>>>>>>>>>CATEGORY>>>>>>>>>>>>>>>>>>>>>>>

export const CREATE_CATEGORY_SUCCESS = "CREATECATEGORYSUCCESS"
export const CREATE_CATEGORY_FAIL = "CREATECATEGORYFAIL"
export const GET_CATEGORY_SUCCESS = "GETCATEGORYSUCCESS"
export const GET_CATEGORY_FAIL = "GETCATEGORYFAIL"
export const DEL_CATEGORY_SUCCESS = "DELCATEGORYSUCCESS"
export const DEL_CATEGORY_FAIL = "DELCATEGORYFAIL"
export const EDIT_CATEGORY_SUCCESS = "EDITCATEGORYSUCCESS"
export const EDIT_CATEGORY_FAIL = "EDITCATEGORYFAIL"

export const CREATE_SUBCATEGORY_SUCCESS = "CREATESUBCATEGORYSUCCESS"
export const CREATE_SUBCATEGORY_FAIL = "CREATESUBCATEGORYFAIL"
export const GET_SUBCATEGORY_SUCCESS = "GETSUBCATEGORYSUCCESS"
export const GET_SUBCATEGORY_FAIL = "GETSUBCATEGORYFAIL"
export const DEL_SUBCATEGORY_SUCCESS = "DELSUBCATEGORYSUCCESS"
export const DEL_SUBCATEGORY_FAIL = "DELSUBCATEGORYFAIL"
export const EDIT_SUBCATEGORY_SUCCESS = "EDITSUBCATEGORYSUCCESS"
export const EDIT_SUBCATEGORY_FAIL = "EDITSUBCATEGORYFAIL"
export const GET_SUBCATEGORY_ID_SUCCESS = "GET_SUBCATEGORY_ID_SUCCESS"
export const GET_SUBCATEGORY_ID_FAIL = "GET_SUBCATEGORY_ID_FAIL"

//*******************ADD PRODUCT******************************/

export const CREATE_PRODUCT_SUCCESS = "CREATEPRODUCTSUCCESS"
export const CREATE_PRODUCT_FAIL = "CREATEPRODUCTFAIL"
export const GET_PRODUCT_SUCCESS="GETPRODUCTSUCCESS"
export const GET_PRODUCT_FAIL="GETPRODUCTFAIL"
export const EDIT_PRODUCT_SUCCESS="EDITPRODUCTSUCCESS"
export const EDIT_PRODUCT_FAIL="EDITPRODUCTFAIL"
export const DEL_PRODUCT_FAIL="DELPRODUCTFAIL"
export const DEL_PRODUCT_SUCCESS="DELPRODUCTSUCCESS"
export const GET_SINGLE_PROD_SUCCESS="GETSINGLEPRODUCTSUCCESS"
export const GET_SINGLE_PROD_FAILURE="GETSINGLEPRODUCTFAILURE"
export const EDIT_PRODUCT_PRICE_SUCCESS ="EDITPRODUCTPRICESUCCESS"
export const EDIT_PRODUCT_PRICE_FAIL="EDITPRODUCTPRICEFAIL"
//****************ADDFEATURE*****************************/


export const CREATE_FEATURE_SUCCESS = "CREATEFEATURESUCCESS"
export const CREATE_FEATURE_FAIL = "CREATEFEATUREFAIL"
export const GET_FEATURED_SUCCESS = "GETFEATUREDSUCCESS"
export const GET_FEATURED_FAIL = "GETFEATUREDFAIL"
export const EDIT_FEATURE_SUCCESS = "EDITFEATURESUCCESS"
export const EDIT_FEATURE_FAIL = "EDITFEATUREFAIL"
export const DEL_FEATURE_SUCCESS = "DELFEATURESUCCESS"
export const DEL_FEATURE_FAIL = "DELFEATUREFAIL"