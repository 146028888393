import React from "react";
import { useSelector } from "react-redux";
import Logo from "../../../assets/images/dashboard/stats.png";

const UserPanel = ({user}) => {

	const { profilelist } = useSelector(state => state.settings)

	console.log({ profilelist });
	
	return (
		<div>
			<div className="sidebar-user text-center">
				<div>
					<img
						className="lazyloaded blur-up"
						style={{width: 50, height: 50, borderRadius: 50, objectFit: "contain", backgroundColor: "#FBDC51"}}
						src={(profilelist?.image && profilelist?.logo) ? `${profilelist?.image}${profilelist?.logo}`: Logo}
						alt="#"
					/>
				</div>
				<h6 className="mt-3 f-14">{user?.name}</h6>
				<p>{user?.role}</p>
			</div>
		</div>
	);
};

export default UserPanel;
