import React, { useState, useEffect } from 'react'
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import CustomInput from '../../common/CustomInput';
import data from './unitdata';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import SelectInput from '../../common/SelectInput';
import { CreateUnit, EditUnit } from '../../../redux/actions/productActions';
import { useDispatch, useSelector } from "react-redux";
import { RESET_ERROR } from '../../../redux/constants/userConstants';

const AddUnit = ({ openModal, onCloseModal, data }) => {
    const { newUnit, updatedunit } = useSelector(state => state.product)
    const dispatch = useDispatch()
    const schema = yup.object({
        name: yup.string().max(40).required("Unit Name field is Required")

    }).required();


    const { register, handleSubmit, setFieldValue, control, formState: { errors }, reset, setValue } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (datas) => {


        if (data) {
            let input = {
                name: datas?.name,
                id: data?._id,
                status: "active"
            }


            dispatch(EditUnit(input))
            setValue("name", "")
        }
        else {

            dispatch(CreateUnit(datas));
            setValue("name", "")
        }
    };
    useEffect(() => {
        if (data) {
            setValue("name", data?.name)
        }
    }, [data])


    const clearclose = () => {
        setValue("name", "")
        onCloseModal()

    }

    return (

        <Modal isOpen={openModal} toggle={clearclose}>
            <ModalHeader toggle={clearclose}>
                <h5
                    className="modal-title f-w-600"
                    id="exampleModalLabel2"
                >
                    Add Unit
                </h5>
            </ModalHeader>
            <ModalBody>
                <Form>
                    <CustomInput
                        fieldLabel='Unit Name :'
                        control={control}
                        error={errors.name}
                        fieldName='name'
                    />
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    color="primary"
                    onClick={handleSubmit(onSubmit)}
                >
                    Save
                </Button>
                {/* <Button
                    type="button"
                    color="secondary"
                    onClick={() => onCloseModal(
                        setValue("name", "")
                    )}
                >
                    Close
                </Button> */}
            </ModalFooter>
        </Modal>

    )
}

export default AddUnit
