import axios from "../../customAxios"
import { EDIT_STATUS_FAIL, EDIT_STATUS_SUCCESS, GET_ORDER_FAIL, GET_ORDER_SUCCESS, GET_SINGLE_ORDER_FAIL, GET_SINGLE_ORDER_SUCCESS, GET_STATUS_ORDER_FAIL, GET_STATUS_ORDER_SUCCESS } from "../constants/orderConstants"
import { EDIT_CATEGORY_FAIL } from "../constants/productConstants"
import { LOADING } from "../constants/userConstants"



export const getOrderList = (page) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`/admin/orders`).then(async response => {
        let data = response.data.data;
        // console.log(data,"data response")
        dispatch({
            type: GET_ORDER_SUCCESS,
            payload: data
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {
        dispatch({
            type: GET_ORDER_FAIL,
            payload: err
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    })
}

export const filterOrder = (data) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/orderfilter`, data).then(async response => {
        let data = response.data.data;
        dispatch({
            type: GET_ORDER_SUCCESS,
            payload: data
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {
        dispatch({
            type: GET_ORDER_FAIL,
            payload: err
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    })
}


export const getSingleOrderList = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`/admin/order/${id}`).then(async response => {
        let data = response.data.data;
   
        dispatch({
            type: GET_SINGLE_ORDER_SUCCESS,
            payload: data
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {
        dispatch({
            type: GET_SINGLE_ORDER_FAIL,
            payload: err
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    })
}


export const EditorderStatus = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/edit-order`,data).then(async response => {
        let data = response.data.data;
        dispatch({
            type:EDIT_STATUS_SUCCESS,
            payload: data
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {
        dispatch({
            type:EDIT_STATUS_FAIL,
            payload: err
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    })
}


export const getOrderStatus = (data) => async (dispatch) => {

    // console.log({data},'inORDER STATUS')
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`/admin/orderstatus`).then(async response => {
        let data = response.data.data;
        // console.log(data,"data in api")
   
        dispatch({
            type:GET_STATUS_ORDER_SUCCESS,
            payload: data
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {
        dispatch({
            type:GET_STATUS_ORDER_FAIL,
            payload: err
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    })


}