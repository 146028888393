import React, { useState, useEffect } from 'react'
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import CustomInput from '../../common/CustomInput';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import moment from 'moment';
const Editunitmodal = ({ openModal, onCloseModal, editdata, variant, setVariantsData, func, getSingleProductData }) => {


    const [offer, setOffer] = useState(false);



    const schema = yup.object({
        // name:yup.string().required("Name feild is required"),
        // Variant_Description: yup.string()
        //     .matches(/^[A-Za-z ]*$/, 'Please enter description')
        //     .max(50).required(),

        // Selling_Price:yup.string() .test(
        //     'Is positive?', 
        //     ' The number must be greater than 0!', 
        //     (value) => value > 0
        //   ).required() ,
        // Regular_Price:yup.string().test(
        //     'Is positive?', 
        //     ' The number must be greater than 0!', 
        //     (value) => value > 0
        //   ).required() , 
        // unit_id:yup.string().required("Please select unit") ,
        // Offer_Price:yup.string().test(
        //     'Is positive?', 
        //     ' The number must be greater than 0!', 
        //     (value) => value > 0
        //   )

    }).required();

    const { register, handleSubmit, setFieldValue, control, formState: { errors }, reset, setValue, getValues } = useForm({
        resolver: yupResolver(schema),

    });

    console.log(editdata,"edit")

    useEffect(() => {
        if (editdata) {

            if(editdata?.offer){
                setOffer(true)
            }
            setValue("name", editdata?.name)
            setValue("Variant_Description", editdata?.description)
            setValue("Selling_Price", editdata?.selling)
            setValue("Regular_Price", editdata?.regular)
            setValue("Offer_Price", editdata?.offer)
            setValue("startDate", editdata?.fromDate ? moment(editdata?.fromDate, "YYYY-MM-DD").format("YYYY-MM-DD"): "")
            setValue("EndDate", editdata?.toDate ? moment(editdata?.toDate, "YYYY-MM-DD").format("YYYY-MM-DD"): "")
            setValue("unit", editdata?.unit)
        }

    }, [editdata, variant])

    
    const Editform = (event) => {
        console.log(event, "event")
        const val = {
            uid: editdata.uid,
            id: editdata.id,
            name: event.name,
            description: event.Variant_Description,
            selling: event.Selling_Price,
            regular: event.Regular_Price,
            offer: event?.Offer_Price ? event?.Offer_Price : null,
            fromDate: event.startDate,
            toDate: event.EndDate
        };

        func(val)

        // const result = variant.filter((res) => res.uid !== val.uid)
        // setVariantsData([...result, val])
        // getSingleProductData()
        onCloseModal()

    }

    const offerselect = (e, checked) => {

        if (checked) {
            setValue("startDate", null)
            setValue("EndDate", null)
            setOffer(true)
        } else {
            setValue("Offer_Price", "")
            setOffer(false)
        }
    }



    return (
        <Modal isOpen={openModal} toggle={onCloseModal}>
            <ModalHeader toggle={onCloseModal}>
                <h5
                    className="modal-title f-w-600"
                    id="exampleModalLabel2"
                >
                    Edit Product
                </h5>
            </ModalHeader>
            <ModalBody>
                <Form>


                    <div>

                        <CustomInput
                            fieldLabel={'Variant Name:'}
                            control={control}
                            error={errors.name}
                            fieldName={'name'}
                        />
                    </div>
                    {/* <div>

                        <CustomInput
                            fieldLabel='Variant Description'
                            control={control}
                            error={errors.Variant_Description}
                            fieldName={'Variant_Description'}
                        />
                    </div> */}
                    <div>

                        <CustomInput
                            fieldLabel='Selling Price'
                            control={control}
                            error={errors.Selling_Price}
                            fieldName={'Selling_Price'}
                            type="number"
                        />
                    </div>
                    <div>

                        <CustomInput
                            fieldLabel='Regular Price'
                            control={control}
                            error={errors.Regular_Price}
                            fieldName={'Regular_Price'}
                            type="number"
                        />
                    </div>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px", }}>
                        <input type="checkbox" checked={offer} onChange={(e) => offerselect(e.target.value, e.target.checked)} style={{ cursor: "pointer" }}></input>
                        <label>Add Offer Price</label>

                    </div>
                        {offer && <div>


                            <CustomInput
                                fieldLabel='Offer Price'
                                control={control}
                                error={errors.Offer_Price}
                                fieldName={'Offer_Price'}
                                type="number"
                            />
                            <div style={{ display: "flex", justifyContent: "space-between" }}>


                                <CustomInput
                                    fieldLabel={'Start Date'}
                                    control={control}
                                    error={errors.startDate}
                                    fieldName={'startDate'}
                                    defaultValue={getValues("startDate")}
                                    type="date"
                                    nin={moment().format("YYYY-MM-DD")}
                                />

                                <CustomInput
                                    fieldLabel={'End Date'}
                                    control={control}
                                    error={errors.EndDate}
                                    fieldName={'EndDate'}
                                    type={'date'}
                                />

                            </div>



                        </div>}

                </Form>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    color="primary"
                    onClick={handleSubmit(Editform)}

                >
                    Save
                </Button>
                <Button
                    type="button"
                    color="secondary"
                    onClick={onCloseModal}
                >
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default Editunitmodal