
import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditProduct from "./EditProduct";
import ViewProduct from "./ViewProduct";
import DeleteProduct from "./DeleteProduct";
import { DELPRODUCT, GETPRODUCTS } from "../../../redux/actions/productActions";
import { useDispatch, useSelector } from "react-redux";
import DataTables from "../../common/DataTables";
import { RESET_ERROR } from "../../../redux/constants/userConstants";
import { useNavigate } from 'react-router-dom'
import DataTable from "react-data-table-component";
import SearchHeader from "../../common/header_components/searchHeader";

const Products = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const { productlist, productdeleted, productpriceupdated } = useSelector(state => state.product);



    const [openedit, setOpenEdit] = useState(false);
    const [openView, setOpenView] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [open, setOpen] = useState(false);
    const [value, setvalue] = useState(null)
    const [filterText, setFilterText] = useState('');
    const [datas, setDatas] = useState([])


    const onOpenDeleteModal = () => {
        setOpenDelete(true);
    };

    const onCloseDeleteModal = () => {
        setOpenDelete(false);
    };
    const onOpenEditModal = () => {
        setOpenEdit(true);
    };

    const onCloseEditModal = () => {
        setOpenEdit(false);
    };

    const onOpenViewModal = () => {
        setOpenView(true);
    };

    const onCloseViewModal = () => {
        setOpenView(false);
    };
    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    useEffect(() => {
        if (productlist) {
            setDatas(productlist)
        }
    }, [productlist])

    const handleDelete = (id) => {
        if (window.confirm("Are you sure you wish to delete this item?")) {
            dispatch(DELPRODUCT(id))
        }
    }


    const handleEdit = (data) => {
        setOpen(true)
    }
    const NavigateEditpage = (id) => {
        navigate(`/products/EditProduct/${id}`);

    }


    useEffect(() => {
        if (productdeleted) {
            toast.success(" Product deleted successfully");
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [productdeleted])

    useEffect(() => {
        // if(filterText){
        let datas = productlist?.filter(ord => ord?.name?.toLowerCase().includes(filterText?.toLowerCase()) || ord?.description?.toLowerCase().includes(filterText?.toLowerCase()) || ord?.category?.name?.toLowerCase().includes(filterText?.toLowerCase()) || ord?.subCategory?.name?.toLowerCase().includes(filterText?.toLowerCase()) || ord?.units?.map(un => un?.name?.toLowerCase()).join(',').includes(filterText?.toLowerCase()))
        setDatas(datas)
        // }
    }, [filterText])

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setFilterText('');
            }
        };

        return (
            <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
                <SearchHeader onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
            </div>
        );
    }, [filterText]);


    var columns = [
        {
            name: <b>{Capitalize("S.No")}</b>,
            header: <b>{Capitalize("S.No")}</b>,
            cell: (row, index) => index + 1
        },
        {
            name: <b>{Capitalize("Image")}</b>,
            header: <b>{Capitalize("Image")}</b>,
            cell: (row, index) => (
                <>
                    <span>
                        <img style={{ width: 40, height: 40 }} src={`${row?.imageBasePath}${row?.image[0]}`}></img>
                    </span>
                </>

            )
        },
        {
            name: <b>{Capitalize("Name")}</b>,
            header: <b>{Capitalize("Name")}</b>,
            selector: row => row?.name,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("description")}</b>,
            header: <b>{Capitalize("description")}</b>,
            selector: row => row?.description === "null" ? "" : row?.description,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("category")}</b>,
            header: <b>{Capitalize("category")}</b>,
            selector: row => row?.category && row?.category?.name,
            style: {
                textAlign: "center",
            },
        },
        // {
        //     name: <b>{Capitalize("subcategory")}</b>,
        //     header: <b>{Capitalize("subcategory")}</b>,
        //     selector: row => row?.subCategory?.name && row?.subCategory?.name,
        //     style: {
        //         textAlign: "center",
        //     },
        // },
        {
            name: <b>{Capitalize("unit")}</b>,
            header: <b>{Capitalize("unit")}</b>,
            selector: row => row?.units && row?.units?.map((res) => (
                <div>
                    <span>{res?.name}</span>
                </div>

            )),
            style: {
                textAlign: "center",
            },
        },

        {
            name: <b>{Capitalize("status")}</b>,
            header: <b>{Capitalize("status")}</b>,
            selector: row => row?.status,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>Actions</b>,
            id: "delete",
            accessor: (str) => "delete",
            cell: (row, index) => (

                <>
                    <span onClick={() => handleDelete(row._id)}>

                        <i
                            className="fa fa-trash"
                            style={{
                                width: 35,
                                fontSize: 20,
                                padding: 11,
                                color: "#e4566e",
                                cursor: "pointer"
                            }}
                        ></i>
                    </span>
                    <span onClick={() => NavigateEditpage(row._id)}>
                        <i
                            className="fa fa-pencil"
                            style={{
                                width: 35,
                                fontSize: 20,
                                padding: 11,
                                color: "rgb(40, 167, 69)",
                                cursor: "pointer"
                            }}
                        ></i>
                    </span>
                </>
            )
        }
    ];




    useEffect(() => {
        dispatch(GETPRODUCTS())
    }, [])

    if (!productlist) {
        return <div>Loading...</div>
    }

    return (
        <Fragment>
            <Breadcrumb title="Products List" parent="Products" />
            {/* <!-- Container-fluid starts--> */}
            <Container fluid="true">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <h5>Product Details</h5>
                            </CardHeader>
                            <CardBody>

                                <div className="clearfix"></div>
                                <div id="basicScenario" className="product-physical">
                                    <Fragment>
                                        {productlist &&
                                            <DataTable
                                                pagination
                                                title=""
                                                columns={columns}
                                                data={datas}
                                                fixedHeader
                                                subHeader
                                                subHeaderComponent={subHeaderComponentMemo}
                                            />}
                                    </Fragment>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>


            {/* <!-- Container-fluid Ends--> */}
        </Fragment>
    )
}

export default Products