import axios from 'axios'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Breadcrumb, Button, Card, CardBody, CardHeader, Col, Container, Row, Spinner } from 'reactstrap'
import customAxios from '../../customAxios'
import AddBlog from './AddBlog'
import { useNavigate } from 'react-router-dom'
import ViewBlog from './ViewBlog'

const Blogs = () => {

    const [blogs, setBlogs] = useState([])
    const [open, setOpen] = useState(false)
    const [viewBlogs, setViewBlogs] = useState(false)
    const [id, setId] = useState(null)

    const navigate = useNavigate()


    useEffect(() => {
        getBlogs()
    }, [])


    const closeModal = () => {
        setId(null)
        setOpen(false)
        getBlogs()
    }


    const getBlogs = async() => {
        try {
            const blogs = await customAxios.get('admin/blogs');

         setBlogs(blogs.data?.data)
        } catch (error) {
            
        }
        finally{
            setLoading(false)
        }
        
    }
    

    const [loading, setLoading] = useState(false)

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1)
    };


    const viewBlog = (id) => {
        setId(id)
        setViewBlogs(true)
    }

    const handleEdit = (id) => {
        setId(id)
        setOpen(true)
    }

    const handledelete = async(id) => {
        setLoading(true)
        if (window.confirm("Are you sure you wish to delete this item?")) {
            await customAxios.delete(`admin/blog/${id}`)
            getBlogs()
        } else {
            getBlogs()
        }
    }

    var columns = [
        {
            name: <b>{Capitalize("Date")}</b>,
            header: <b>{Capitalize("Date")}</b>,
            selector: row => `${moment(row?.created_at).format('DD-MM-YYYY')}`,
            style: {
                textAlign: "center",
            },

        },
        {
            name: <b>{Capitalize("title")}</b>,
            header: <b>{Capitalize("title")}</b>,
            selector: row => row?.title,
            style: {
                textAlign: "center",
            },

        },
        {
            name: <b>{Capitalize("description")}</b>,
            header: <b>{Capitalize("Description")}</b>,
            selector: row => row?.description,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("status")}</b>,
            header: <b>{Capitalize("status")}</b>,
            selector: row => row?.status,
            style: {
                textAlign: "center",
            },
        },

        {
            name: <b>Actions</b>,
            id: "delete",
            accessor: (str) => "delete",
            cell: (row, index) => (

                <>
                    <span 
                    onClick={() => viewBlog(row?._id)} 
                    >

                        <i
                            className="fa fa-eye"
                            style={{
                                width: 35,
                                fontSize: 20,
                                padding: 11,
                                color: "#e4566e",
                                cursor: "pointer"
                            }}

                        ></i>
                    </span>

                    <span 
                        onClick={() => handleEdit(row?._id)}
                    >
                        <i
                            className="fa fa-pencil"
                            style={{
                                width: 35,
                                fontSize: 20,
                                padding: 11,
                                color: "rgb(40, 167, 69)",
                                cursor: "pointer"
                            }}
                        ></i>
                    </span>
                    <span 
                        onClick={() => handledelete(row?._id)}
                    >
                        <i
                            className="fa fa-trash"
                            style={{
                                width: 35,
                                fontSize: 20,
                                padding: 11,
                                color: "red",
                                cursor: "pointer"
                            }}
                        ></i>
                    </span>
                </>
            )
        }
    ];


    const subHeaderComponentMemo = React.useMemo(() => {

        return (
            <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
                <Button
                    type="button"
                    color="primary"
                    onClick={() => setOpen(true)}
                >
                    Create Blog
                </Button>
            </div>
        );
    }, []);


    return (
        <Fragment>
            <Breadcrumb title="Blogs" parent="Order" />
            {/* <!-- Container-fluid starts--> */}
            <Container fluid="true">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <h5>Blogs</h5>
                            </CardHeader>
                            <CardBody>

                                <div className="clearfix"></div>
                                <div id="basicScenario" className="product-physical">
                                    <Fragment>
                                        <DataTable
                                            pagination
                                            title=""
                                            columns={columns}
                                            progressPending={loading}
                                            
                                            
                                            //paginationTotalRows={orderList?.total}
                                            //onChangeRowsPerPage={handlePerRowsChange}
                                            progressComponent={<Spinner animation="grow" variant="info" />}
                                            //paginationComponentOptions={paginationComponentOptions}
                                            //onChangePage={handlePageChange}
                                            data={blogs}
                                            fixedHeader
                                            subHeader
                                            subHeaderComponent={subHeaderComponentMemo}
                                        />

                                    </Fragment>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <AddBlog openModal={open} onCloseModal={closeModal} id={id ? id : null} />
            {(viewBlogs && id) && <ViewBlog openModal={viewBlogs} onCloseModal={() => {
                setId(null)
                setViewBlogs(false)
            }} id={id} />}
        </Fragment>
    )
}

export default Blogs