
import React, { Fragment, useEffect, useState } from 'react'
import { Breadcrumb, Card, Col, Container, CardBody, Button, CardHeader, Row } from 'reactstrap'
import DataTable from "react-data-table-component";
import customAxios from '../../../customAxios';
import { toast } from "react-toastify";
import AddMarkettingModal from './AddMarkettingModal';
import moment from 'moment';
import SearchHeader from '../../common/header_components/searchHeader';

const Marketting = () => {

    const [open, setOpen] = useState(false);
    const [product, setProduct] = useState([]);
    const [category, setCategory] = useState([]);
    const [item, setItem] = useState([])
    const [activeData, setActiveData] = useState(null)
    const [filterText, setFilterText] = useState('');
    const [search, setSearch] = useState([])
    const [openEdit, setOpenEdit] = useState(false);



    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };


    const handleDelete = async (id) => {


    }
    const onOpenModal = () => {

        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };


    const getProductCategory = async () => {
        try {
            const { data: product } = await customAxios.get('admin/marketing/product_list');
            setProduct(product?.data?.map((res) => ({ value: res?._id, label: res?.name })));
            const { data: category } = await customAxios.get('admin/marketing/category_list');
            setCategory(category?.data?.map((res) => ({ value: res?._id, label: res?.name })))

        } catch (err) {

        }
    }

    const getList = async () => {
        try {
            const { data } = await customAxios.get('admin/marketing')
            setItem(data?.data);
            setSearch(data?.data)
        } catch (err) {
            toast.warning(err.message)
        }
    }

    useEffect(() => {
        getList()
    }, []);


    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setFilterText('');
                //  if(filterText.length === 0)
            }
        };

        return (
            <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
                <SearchHeader onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
            </div>
        );
    }, [filterText]);



    useEffect(() => {
   
            let datas = search?.filter(cat => cat?.type?.toLowerCase().includes(filterText?.toLowerCase()) || cat?.product?.name && cat?.product?.name.toLowerCase().includes(filterText?.toLowerCase()) || cat?.category?.name && cat?.category?.name.toLowerCase().includes(filterText?.toLowerCase()) || cat?.display_order?.toLowerCase().includes(filterText?.toLowerCase()))
            setItem(datas)
     
    }, [filterText])



    const deleteData = async (id) => {
        try {
            const { data } = await customAxios.delete(`admin/marketing/delete/${id}`);
            toast.success('Deleted Successfully')
            getList()
        } catch (err) {

        }
    }





    useEffect(() => {
        getProductCategory()
    }, []);


    const handleEdit = (row) => {

        setActiveData(row);
        setOpenEdit(true);

    }


    var columns = [
        {
            name: <b>{Capitalize("S.No")}</b>,
            header: <b>{Capitalize("S.No")}</b>,
            cell: (row, index) => index + 1
        },
        {
            name: <b>{Capitalize("Created At")}</b>,
            header: <b>{Capitalize("Created At")}</b>,
            cell: (row, index) => moment(row?.created_at).format('DD-MM-YYYY hh:mm A')
        },

        {
            name: <b>{Capitalize("Type")}</b>,
            header: <b>{Capitalize("Type")}</b>,
            selector: row => row?.type,
            style: {
                textAlign: "center",
            },
        },


        {
            name: <b>{Capitalize("product")}</b>,
            header: <b>{Capitalize("product")}</b>,
            selector: row => row?.product?.name ? row?.product?.name : '-',
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("Category")}</b>,
            header: <b>{Capitalize("Category")}</b>,
            selector: row => row?.category?.name ? row?.category?.name : '-',
            style: {
                textAlign: "center",
            },
        },


        {
            name: <b>{Capitalize("Display Order")}</b>,
            header: <b>{Capitalize("Display Order")}</b>,
            selector: row => row?.display_order,
            style: {
                textAlign: "center",
            },
        },
        // {
        //     name: <b>{Capitalize("link")}</b>,
        //     header: <b>{Capitalize("Link")}</b>,
        //     selector: row => row?.link,
        //     style: {
        //         textAlign: "center",
        //     },
        // },
        {
            name: <b>Actions</b>,
            id: "delete",
            accessor: (str) => "delete",
            cell: (row, index) => (

                <>
                    <span onClick={() => deleteData(row?._id)} >

                        <i
                            className="fa fa-trash"
                            style={{
                                width: 35,
                                fontSize: 20,
                                padding: 11,
                                color: "#e4566e",
                                cursor: "pointer"
                            }}
                        ></i>
                    </span>
                    <span onClick={() => handleEdit(row)}>
                        <i
                            className="fa fa-pencil"
                            style={{
                                width: 35,
                                fontSize: 20,
                                padding: 11,
                                color: "rgb(40, 167, 69)",
                                cursor: "pointer"
                            }}
                        ></i>
                    </span>

                </>
            )
        }
    ];

    return (
        <Fragment>
            <Breadcrumb title="Marketing" parent="Settings" />
            {/* <!-- Container-fluid starts--> */}
            <Container fluid="true">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <h5>Marketing</h5>
                            </CardHeader>
                            <CardBody>
                                <div className="btn-popup pull-right">
                                    <Button
                                        type="button"
                                        color="secondary"
                                        onClick={onOpenModal}
                                        data-toggle="modal"
                                        data-original-title="test"
                                        data-target="#exampleModal"

                                    >
                                        Add Marketing

                                    </Button>

                                </div>
                                <div className="clearfix"></div>
                                <div id="basicScenario" className="product-physical">
                                    <DataTable
                                        pagination
                                        columns={columns}
                                        data={item}
                                        fixedHeader
                                        subHeader
                                        subHeaderComponent={subHeaderComponentMemo}
                                    />

                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {open && <AddMarkettingModal

                product={product}
                category={category}
                refetch={getList}
                openModal={open}
                onCloseModal={() => {
                    setOpen(false)
                }}
                title={'Add Marketing'}
            />}
            {openEdit && <AddMarkettingModal
                item={activeData}
                product={product}
                category={category}
                refetch={getList}
                openModal={openEdit}
                onCloseModal={() => {
                    setOpenEdit(false)
                }}
                title={'Edit Marketing'}
            />}


        </Fragment>
    )
}

export default Marketting