import React, { useState, useEffect } from 'react'
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import CustomInput from '../../common/CustomInput';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, mixed } from "yup"
import * as yup from 'yup'
import SelectInput from '../../common/SelectInput';
import TimePicker from 'react-time-picker';
import customAxios from '../../../customAxios';
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import moment from 'moment';

const AddHolidaySlotModal = ({ openModal, onCloseModal, data, setActiveData, title,refetch }) => {


    const [startDate, setstartDate] = useState(new Date());

    // console.log({ startDate })

    const handleStartDate = (date) => {
        setstartDate(date);
    };



    const schema = yup.object({
        // day: yup.string().required('Required')
    })

    const { register, handleSubmit, setFieldValue, control, formState: { errors }, reset, setValue, setError } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {

        }
    });


    const clearclose = () => {
        onCloseModal()
        reset()
    }

    const Submit = async (data) => {
        let date = { date: moment(startDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
        comment:data?.comment }

        try {

            await customAxios.post('admin/delivery-leave', { ...date })
            refetch()
            toast.success(`Created Successfully`);
            clearclose()
        } catch (err) {
            toast.error(err.message);
        } finally {

        }

    }




    return (
        <Modal isOpen={openModal} toggle={clearclose} size="lg">
            <ModalHeader toggle={clearclose}>
                <h5
                    className="modal-title f-w-600"
                    id="exampleModalLabel2"
                >
                    {title}
                </h5>
            </ModalHeader>
            <ModalBody>
                <Form>
                    <Row>
                        <Col xl={4}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Label
                                    htmlFor="recipient-name"
                                    className="col-form-label"
                                >
                                    {'Holiday'}
                                </Label>
                                <DatePicker
                                selected={startDate}
                                onChange={handleStartDate}
                            />
                            </div>
                           
                        </Col>
                        <Col xl={6}>
                            <CustomInput
                                fieldLabel='Comments'
                                control={control}
                                error={errors.comment}
                                fieldName='comment'
                                type="textarea"
                            />
                        </Col>

                    </Row>

                    {/* <span style={{ color: "red" }}>Image resolution should be 768px * 1000px</span> */}
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    color="primary"
                    onClick={handleSubmit(Submit)}
                >
                    Save
                </Button>

            </ModalFooter>

        </Modal>
    )
}

export default AddHolidaySlotModal